import { useState, useCallback, useRef } from "react"
import { useFunctions } from "./useFunctions"

const INITIAL_STATE = {
  firstname: "",
  lastname: "",
  email: "",
  dob: "",
}

export const useForm = () => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState(INITIAL_STATE)
  const { callFunction } = useFunctions()

  const submitForm = useCallback(
    async (data: any) => {
      setLoading(true)
      setErrors([])

      const { status, body } = await callFunction("subscribe", {
        ...data,
        dob: {
          year: parseInt(data.dob.split("-")[0]),
          month: parseInt(data.dob.split("-")[1]),
          day: parseInt(data.dob.split("-")[2]),
        },
      })

      if (status === "error") setErrors([body])
      if (status === "success") setData(INITIAL_STATE)

      setLoading(false)

      return status === "success"
    },
    [setLoading, setErrors, setData, callFunction]
  )

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  return { submitForm, data, setData, handleChange, loading, errors }
}

export const useFocus = () => {
  const ref = useRef(null)
  const setFocus = () => {
    //@ts-ignore next-line
    ref.current && ref.current.focus()
  }

  return [ref, setFocus]
}
