import React, { useState } from 'react'
import { Container, Flex, Box } from '@chakra-ui/react'
import Brand from '../components/Brand'
import NavigationHeader from '../components/Navigation/Header/NavigationHeader'
import MobileMenuIcon from '../components/Navigation/Header/MobileMenuIcon'
import NavigationHeaderDesktopMega from '../components/Navigation/Header/NavigationHeaderDesktopMega'
import SearchSubscribe from '../components/SearchSubscribe'
import DFPAd from '../components/Ads/DFPAd'
import { useMediaDesktop, useMedia, useMediaMobile } from '../hooks/useMedia'
import { useScrollDirection } from '../hooks/useScrollDirection'
import { useScrollPosition } from '../hooks/useScrollPosition'
import { useCurrentIssue } from '../hooks/useCurrentIssue'
import { useMasthead } from '../hooks/useMasthead'
import Link from './Link'
import { useCurrentMonthlyIssue } from '../hooks/useCurrentMonthlyIssue'

type Props = {
  location: any
  data: any
}

const Header: React.FC<Props> = ({ data, location }) => {
  const isDesktop = useMediaDesktop()
  const isMobile = useMediaMobile()
  const masthead = useMasthead()

  const { isXSmall, isSmall, isMedium } = useMedia()
  const [sticky, setSticky] = useState(false)
  const { scrollDir } = useScrollDirection()
  const [megaActive, setMegaActive] = useState(false)
  // target homepage, articles and categories for the top header ad
  const isMobileAdDisplay =
    !isDesktop &&
    isXSmall &&
    (location.pathname === '/' || data?.article || data?.category)
  const isDesktopAdDisplay =
    isDesktop &&
    !isXSmall &&
    (location.pathname === '/' || data?.article || data?.category)
  const { handle, openCurrentMonthlyIssue } = useCurrentMonthlyIssue()
  const { headerFooterTextBtn } = useCurrentIssue()

  useScrollPosition(
    ({ currPos }: { prevPos: any; currPos: any }) => {
      if (currPos.y < -10 && scrollDir === 'up') {
        setSticky(true)
      } else {
        setSticky(false)
      }
    },
    [sticky, scrollDir],
  )

  return (
    <>
      {isDesktopAdDisplay && (
        <DFPAd
          py={[4, 8]}
          slotId="gam_header_pos1"
          type="header"
          position={1}
        />
      )}
      <Container
        as="header"
        maxW="10xl"
        px={['4', '0']}
        className="header-container"
        pos={sticky && !isMobile ? 'sticky' : 'static'}
        top="0"
        right="0"
        left="0"
        bg={megaActive || sticky ? 'white' : 'transparent'}
        zIndex={10}
        transition="all 0.4s ease"
      >
        <>
          <Flex
            className="home-brand-container"
            justify="space-between"
            align="center"
            px={['0', '4']}
            py="4"
            position="relative"
            transition="all 0.15s ease"
          >
            <Box
              d="flex"
              justifyContent="space-between"
              width={['100%', 'auto']}
            >
              <Flex
                alignItems="center"
                flex="1"
                opacity={
                  megaActive ||
                  sticky ||
                  isMobile ||
                  (isSmall && !isMedium) ||
                  location.pathname !== '/'
                    ? '1'
                    : '0'
                }
              >
                <Box ml={['0', '4']}>
                  <Brand fill={masthead.color || '#333'} width="150px" />
                </Box>
                <Box
                  pl="8"
                  onClick={openCurrentMonthlyIssue}
                  color={masthead.color || 'var(--chakra-colors-brand-black)'}
                  fontSize={isDesktop ? 'md' : '12px'}
                  cursor="pointer"
                >
                  <Link href={`/${handle}`}>{headerFooterTextBtn}</Link>
                </Box>
              </Flex>
            </Box>
            <Flex whiteSpace="nowrap" direction="column">
              <Flex
                direction="row"
                justifyContent="flex-end"
                mr={['0', '4']}
                mb={['0', '4']}
                display={sticky ? 'none' : 'flex'}
              >
                <SearchSubscribe />
                {isMobile && <MobileMenuIcon />}
              </Flex>
              <NavigationHeader setMegaActive={setMegaActive} />
            </Flex>
          </Flex>

          {isDesktop && (
            <Flex alignSelf="flex-end" position="relative" height="1px">
              <NavigationHeaderDesktopMega
                megaActive={megaActive}
                setMegaActive={setMegaActive}
              />
            </Flex>
          )}
          {isMobileAdDisplay && (
            <DFPAd py={4} slotId="gam_header_pos1" type="header" position={1} />
          )}
        </>
      </Container>
    </>
  )
}

export default Header
