import React from "react"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Link as CustomLink, LinkProps } from "@chakra-ui/react"
import { useBreadcrumb } from "../hooks/useBreadcrumb"

interface GatsbyProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, '"ref"'> {
  external?: boolean
  state?: any
}

type Props = GatsbyProps & LinkProps

const Link: React.FC<Props> = ({ children, to = "", external = false, state = {}, ...other }) => {
  const { breadcrumbState } = useBreadcrumb()
  const internal = /^\/(?!\/)/.test(to)
  const customState = breadcrumbState(state)

  return internal ? (
    <CustomLink as={GatsbyLink} to={to} state={customState} _activeLink={{ textDecoration: "underline" }} {...other}>
      {children}
    </CustomLink>
  ) : (
    <CustomLink href={to} {...(external && { isExternal: true, target: "_blank", rel: "noreferrer" })} {...other}>
      {children}
    </CustomLink>
  )
}

export default React.memo(Link)
