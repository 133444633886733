import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Flex, Text, Box } from "@chakra-ui/react"
import Link from "../components/Link"
import Icon from "../components/Icon"

type Props = {
  color?: string
  fontSize?: string
  fill?: string
  stroke?: string
  mt?: string
  alignment?: string
}
const SocialMedia: React.FC<Props> = ({ color, fontSize, fill, stroke, mt, alignment }) => {
  const { social } = useStaticQuery<GatsbyTypes.StaticSocialMediaQuery>(graphql`
    query StaticSocialMedia {
      social: sanitySettingSocial {
        channels {
          title
          url
          displayOrder
        }
        additionalSocialText
      }
    }
  `)

  return (
    <Box mb={10} mt={mt || "unset"}>
      <Text
        color={color || "white"}
        mb={4}
        textTransform="uppercase"
        fontSize={fontSize || "md"}
        textAlign={alignment || ["center", "left"]}
        letterSpacing={["1.12px", "1.6px"]}
      >
        {social?.additionalSocialText}
      </Text>
      <Flex flexDirection="row" justifyContent={alignment || ["center", "unset"]}>
        {social?.channels &&
          social.channels
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            .map((item, idx, arr) => {
              const title = item?.title?.toString().toLowerCase()
              return (
                <Link color={color || "white"} key={title} to={item?.url || ""} external mr={idx === arr.length - 1 ? 0 : 8}>
                  {title && (
                    <Icon
                      name={title}
                      stroke={stroke || "white"}
                      fill={title === "pinterest" ? "white" : fill || "inherit"}
                      height={22}
                      width={22}
                    />
                  )}
                </Link>
              )
            })}
      </Flex>
    </Box>
  )
}

export default React.memo(SocialMedia)
