import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Container, Stack, Text, Image, Flex } from '@chakra-ui/react'
import Copyright from '../components/Copyright'
import SocialMedia from '../components/SocialMedia'
import NavigationFooter from '../components/Navigation/Footer/NavigationFooter'
import SubscribeButton from '../components/Subscribe/SubscribeButton'
import { useCurrentIssue } from '../hooks/useCurrentIssue'
import { useMediaMobile } from '../hooks/useMedia'

type Props = {
  location?: string
}

const Footer: React.FC<Props> = () => {
  const { global } = useStaticQuery<GatsbyTypes.StaticFooterQuery>(graphql`
    query StaticFooter {
      global: sanityTemplateGlobal {
        addtionalAcknowledgement
      }
    }
  `)

  const isMobile = useMediaMobile()
  const { coverImage, headerFooterTextBtn, openCurrentIssue } =
    useCurrentIssue()

  return (
    <>
      <Box as="footer" bgColor="black" color="white" py="16">
        <Container maxW="container.xl" px="8">
          <Stack direction={['column', 'column', 'row']} spacing={['16', '16']}>
            <Flex direction={['column', 'row', 'row']}>
              <Box flex="1">
                <NavigationFooter />
              </Box>

              <Box
                maxW={['full', '344px', '310px']}
                alignSelf={['auto', 'auto', 'flex-end']}
              >
                <Text
                  color="white"
                  textAlign={['center', 'left', 'left']}
                  fontSize="md"
                  mt={['70px', '70px', '0']}
                >
                  {global?.addtionalAcknowledgement}
                </Text>
              </Box>
            </Flex>
            {!isMobile && (
              <Box
                borderRight="thin"
                bg="white"
                opacity="0.45"
                alignSelf="flex-end"
                w={['full', 'full', '1px']}
                h={['1px', '1px', '360px']}
                marginInlineStart="20px !important"
                marginInlineEnd="-30px !important"
              />
            )}
            <Flex
              direction={['column', 'row', 'row']}
              alignItems={['center', 'unset']}
            >
              <Box
                my={['4', '0']}
                alignSelf={['auto', 'auto', 'flex-end']}
                flex="1"
                mr={['0', '30px']}
                onClick={openCurrentIssue}
                cursor="pointer"
              >
                <Box
                  onClick={openCurrentIssue}
                  cursor="pointer"
                  textTransform="uppercase"
                  textAlign={isMobile ? 'center' : 'left'}
                >
                  {headerFooterTextBtn}
                </Box>
                <Image mt="2" {...coverImage} width="190px" minW="100px" />
              </Box>

              <Box
                my={['4', '0']}
                width={['100%', 'auto']}
                maxW="380px"
                alignSelf={['auto', 'flex-end', 'flex-end']}
                mt={['40px', '0']}
              >
                <SocialMedia />
                <SubscribeButton />
              </Box>
            </Flex>
          </Stack>
        </Container>
      </Box>
      <Copyright />
    </>
  )
}

export default React.memo(Footer)
