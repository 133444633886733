import React from "react"
import { BiX } from "react-icons/bi"
import { Container, Flex, Box, Center, Text, IconButton } from "@chakra-ui/react"
import { useAnnouncement } from "../hooks/useAnnouncement"
import Link from "../components/Link"

const Announcement: React.FC = () => {
  const { items, settings, show, hide } = useAnnouncement()

  return show && settings?.visibility ? (
    <Box as="section" bg="brand.primary">
      <Container maxW={"11xl"}>
        <Flex as="section" justify="space-between" align="center" color="white" px={[0, 4]} py={1}>
          <Box d="flex"></Box>
          <Center d="flex" flex={1} flexGrow={1}>
            {items?.map(({ link, title }, index) =>
              link?.link ? (
                <Text as={Link} key={index} to={link?.link} size="xs" color="white" fontSize={12} fontWeight={600} px={4} py={2}>
                  {title}
                </Text>
              ) : (
                <Text key={index} size="xs" color="white" fontSize={12} fontWeight={600} px={4} py={2}>
                  {title}
                </Text>
              )
            )}
          </Center>
          <Box d="flex">
            <IconButton
              variant="ghost"
              colorScheme="brand.text"
              aria-label="Close announcement"
              icon={<BiX />}
              onClick={hide}
              fontSize={"xl"}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  ) : null
}

export default React.memo(Announcement)
