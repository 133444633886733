import { useLayoutEffect, useState } from 'react'
import { NEW_ISSUE_MOBILE_WIDTH } from '../../../constants/NewIssue.constatns'

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined)
  useLayoutEffect(() => {
    const updateSize = (): void => {
      const isM =
        window.screen.width <= NEW_ISSUE_MOBILE_WIDTH ||
        window.innerWidth <= NEW_ISSUE_MOBILE_WIDTH
      setIsMobile(isM)
    }
    //    window.addEventListener('resize', debounce(updateSize, 250))
    window.addEventListener('resize', updateSize)
    updateSize()
    return (): void => window.removeEventListener('resize', updateSize)
  }, [])

  return {
    isMobile,
  }
}
