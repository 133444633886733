import { extendTheme } from "@chakra-ui/react"

import { breakpoints } from "./breakpoints"
import { styles } from "./styles"
import { colors } from "./colors"
import { fonts } from "./fonts"
import { sizes } from "./sizes"
import { Button, Heading, Text, Select, Radio, Input, FormLabel, FormError, Checkbox, Divider, Modal, Accordion } from "./components"

const overrides = {
  breakpoints,
  styles,
  colors,
  sizes,
  fonts,
  components: {
    Button,
    Heading,
    Text,
    Select,
    Radio,
    Input,
    FormLabel,
    FormError,
    Checkbox,
    Divider,
    Modal,
    Accordion,
  },
}

export default extendTheme(overrides)
