import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import Icon from "../components/Icon"
import { useAppContext } from "../providers/app"
import { useMediaMobile } from "../hooks/useMedia"
import Link from "../components/Link"

const SearchSubscribe = () => {
  const { dispatch } = useAppContext()
  const isMobile = useMediaMobile()

  /*   const handleSubscribeClick = () => {
    dispatch({
      type: "subscribe",
      payload: true,
    })
  } */

  const handleSearchClick = () => {
    dispatch({
      type: "search",
      payload: true,
    })
  }

  return (
    <Flex className="search-subscribe" alignItems="center">
      <Flex alignItems="center" mr="2" onClick={handleSearchClick} cursor="pointer">
        <Icon name="magnify" height={15} width={15} />
        {!isMobile && (
          <Text color="brand.greyPrimary" ml="2" fontSize="12px" cursor="pointer">
            Search{/* TODO: add to sanity */}
          </Text>
        )}
      </Flex>
      {!isMobile && (
        <>
          <Text
            color="brand.greyPrimary"
            fontSize="12px"
            cursor="pointer"
            p={1}
            mr={2}
            as={Link}
            to="https://subscribe.instyleaustralia.com.au" /* onClick={handleSubscribeClick} */
          >
            Subscribe
          </Text>
          <Text
            color="brand.greyPrimary"
            fontSize="12px"
            cursor="pointer"
            as="span"
            _hover={{ color: "brand.secondary" }}
            onClick={() => {
              dispatch({
                type: "subscribe",
                payload: true,
              })
            }}
          >
            Sign up
          </Text>
        </>
      )}
    </Flex>
  )
}

export default SearchSubscribe
