export const Modal = {
  baseStyle: {
    dialogContainer: {},
    dialog: {
      borderRadius: "none",
      bg: "white",
      my: 0,
      boxShadow: "none",
    },
    body: {
      px: [0, 24],
      py: 0,
    },
    header: {
      px: [2, 12],
      py: 6,
    },
    overlay: {
      bg: "overlay",
    },
  },
  defaultProps: {
    size: "3xl",
  },
}
