export const sizes = {
  "9xl": "100rem",
  "10xl": "110rem",
  "11xl": "120rem",
  container: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1180px",
  },
}

/* 
9xl   8rem        128px
8xl   6rem        96px
7xl   4.5rem      72px
6xl   3.75rem     60px
5xl   3rem        48px
4xl   2.25rem     36px
3xl   1.875rem    30px
2xl   1.5rem      24px
xl    1.25rem     20px
lg    1.125rem    18px
md    1rem        16px
sm    0.875rem    14px
xs    0.75rem     12px
*/
