import React, { useEffect } from "react"
import { useAnalytics } from "../hooks/useAnalytics"
import { useAppContext } from "../providers/app"
import { useMaintenance } from "../hooks/useMaintenance"

import type { Location } from "../../types/global"
import { useDFPContext } from "../providers/dfp"

export const withLayout =
  (Component: React.FC<{ data: any; location: Location }>) =>
  ({ name = "Layout", location, children, data }: any) => {
    const { dispatch } = useAppContext()
    const { active, authorised } = useMaintenance(location)
    const { trackPageView } = useAnalytics()
    const { updateAdAttributes, setAdUnit } = useDFPContext()

    useEffect(() => {
      trackPageView()
      setAdUnit(null)
      updateAdAttributes(data, location)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, trackPageView])

    useEffect(() => {
      dispatch({ type: "initial" })
    }, [location?.pathname, dispatch])

    Component.displayName = name
    return active ? (
      <>{children}</>
    ) : (
      authorised && (
        <Component data={data} location={location}>
          {children}
        </Component>
      )
    )
  }
