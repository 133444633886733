import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useCurrentIssue } from "../../hooks/useCurrentIssue"

type Props = {
  color?: string
  textTransform?: string
  textAlign?: string
  fontSize?: string
}

const CurrentIssueDate: React.FC<Props> = ({ color = "white", textTransform = "uppercase", textAlign, fontSize }) => {
  const { issueShortMonthAndYear, openCurrentIssue } = useCurrentIssue()

  return (
    <Box>
      <Text
        color={color}
        textTransform={textTransform}
        fontSize={fontSize || "md"}
        textAlign={textAlign || ["center", "left", "left"]}
        onClick={openCurrentIssue}
      >
        {issueShortMonthAndYear} Issue{" "}
      </Text>
    </Box>
  )
}

export default React.memo(CurrentIssueDate)
