import { useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "./useCore"
import { useConfigContext } from "../providers/config"

export const useAnnouncement = () => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    settings: { keys },
  } = useConfigContext()

  const [show, setShow] = useState(storage.get(keys.announcement) !== "hidden")

  const { data } = useStaticQuery<GatsbyTypes.StaticAnnouncementsQuery>(graphql`
    query StaticAnnouncements {
      data: sanitySettingAnnouncement {
        duration
        visibility
        announcements {
          title
          link {
            link
            external
          }
        }
      }
    }
  `)

  const settings =
    {
      duruation: data?.duration,
      visibility: data?.visibility,
    } || {}
  const items = data?.announcements || []

  const hide = useCallback(() => {
    storage.set(keys.announcement, "hidden")
    setShow(false)
  }, [setShow, storage, keys])

  return {
    items,
    show,
    hide,
    settings,
  }
}
