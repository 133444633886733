export const ButtonTranslateX = {
  "&>span>svg": { transition: "all 0.2s ease", transform: "translateX(40%)" },
}

export const ButtonTranslateXReverse = {
  "&>span>svg": { transition: "all 0.2s ease", transform: "translateX(-40%)" },
}

export const Button = {
  baseStyle: {
    border: "unset",
    borderRadius: "none",
    width: "full",
    fontWeight: "normal",
    lineHeight: "17.95px",
    letterSpacing: ["1.12px", "1.6px"],
    p: "20px 0",
    textTransform: "uppercase",
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      pointerEvents: "none",
      opacity: 0.7,
    },
    _hover: {
      background: "brand.secondary",
      color: "brand.white",
      border: "none",
    },
  },
  variants: {
    outlined: {
      borderColor: "brand.black",
      border: "1px solid",
      backgroundColor: "brand.white",
      fontSize: "md",
      _hover: {
        border: "1px solid",
        borderColor: "brand.secondary",
      },
    },
    outlinedReversed: {
      borderColor: "brand.white",
      color: "brand.white",
      border: "1px solid",
      backgroundColor: "transparant",
      fontSize: "md",
      _hover: {
        border: "1px solid",
        borderColor: "brand.secondary",
      },
    },
    filled: {
      background: "brand.black",
      color: "brand.white",
      fontSize: "md",
    },
    filledReversed: {
      background: "brand.white",
      fontSize: "md",
    },
    textIcon: {
      borderColor: "transparent",
      color: "brand.secondary",
      fontSize: "md",
      width: "auto",
      padding: "0",
      height: "4",
      lineHeight: "unset",
      _hover: {
        backgroundColor: "unset",
        color: "brand.secondary",
      },
    },
    icon: {
      p: "0",
      margin: "0",
      _hover: {
        background: "none",
        color: "current-color",
      },
    },
  },
  /*   defaultProps: {
    size: "sm",
  }, */
}
