import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"
import { useConfigContext } from "../providers/config"

import type { Location } from "../../types/global"

type BreadcrumbProps = {
  data: any
}

export const useBreadcrumb = (location?: Location) => {
  const {
    helpers: { capitalise },
  } = useCore()
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const breadcrumb = urlResolver({ title: "Home" }, "/home")

  const { global, organisation } = useStaticQuery<GatsbyTypes.StaticBreadcrumbsQuery>(graphql`
    query StaticBreadcrumbs {
      global: sanityTemplateGlobal {
        title
      }
      organisation: sanitySettingOrganisation {
        title
        separator
      }
    }
  `)

  const cleanTitle = (title: string) =>
    title
      ?.replace(global?.title || "", "")
      ?.replace(organisation?.title || "", "")
      ?.replace(` ${organisation?.separator} `, "")
      ?.replace(organisation?.separator || "", "")

  const breadcrumbState = (state: any) =>
    location?.pathname.includes(`${routes.CATEGORY}/`) && typeof window !== "undefined"
      ? {
          ...state,
          breadcrumbs: [
            {
              title: cleanTitle(window.document.title),
              url: location.pathname,
            },
          ],
        }
      : state

  const buildBreadcrumbs = ({ data }: BreadcrumbProps): Array<BreadcrumbProps> => {
    const items: Array<any> = [breadcrumb]
    const item = data?.page || data?.article || data?.category
    const currentUrl = urlResolver(item)?.url
    const paths = currentUrl?.split("/")?.slice(1)

    if (data?.article) {
      if (location.state?.breadcrumbs) {
        items.push(...location.state.breadcrumbs)
      }
    }

    if (item) {
      paths?.map(path => {
        if (path === item?.handle?.current) {
          items.push({
            ...item,
            title: item?.title?.trim(),
            url: urlResolver(item)?.url,
          })
        } else {
          items.push({
            ...item,
            title: `${capitalise(path?.trim())}`,
            url: `/${path}`,
          })
        }
      })
    }

    return items
  }

  return {
    breadcrumb,
    buildBreadcrumbs,
    breadcrumbState,
  }
}
