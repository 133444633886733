export const colors = {
  brand: {
    primary: "#000000",
    secondary: "#BF2338",
    greyPrimary: "#828282",
    greySecondary: "#C7C7C7",
    greyTertiary: "#F2F2F2",
    greyQuaternary: "#BDBDBD",
    greyAlt: "#4F4F4F",
    black: "#000000",
    white: "#ffffff",
    pink: "#F2E5E7",
    pinkTint: "#F8F1F3",
  },
}
