import React from 'react'
import { useCore } from '../../../hooks/useCore'
import { useMediaMobile } from '../../../hooks/useMedia'
import NavigationFooterDesktop from './NavigationFooterDesktop'
import NavigationFooterMobile from './NavigationFooterMobile'

const NavigationFooter: React.FC = () => {
  const {
    helpers: { ErrorBoundary, isBrowser },
  } = useCore()
  const isMobile = useMediaMobile()

  return isBrowser ? (
    <ErrorBoundary>
      {isMobile ? <NavigationFooterMobile /> : <NavigationFooterDesktop />}
    </ErrorBoundary>
  ) : null
}

export default React.memo(NavigationFooter)
