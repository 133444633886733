import { useDFPAds } from '../hooks/useDFPAds'
import React, { useCallback, useEffect } from 'react'
import { DFPSlotsProvider, DFPManager } from 'react-dfp'
import slugify from 'slugify'

type DFPProps = {
  targeting: any
  adUnit: string | null
  setAdUnit: (unit: string | null) => void
  updateAdAttributes: (data: any, location: any) => void
}

export const DFPContext = React.createContext<DFPProps | undefined>(undefined)

export const DFPProvider: React.FC = ({ children }) => {
  const {
    networkId,
    env,
    getKvAdTest,
    skin,
    sponsored,
    site,
    lazyLoadParams,
    createOopAds,
    targeting,
    setTargeting,
    adUnit,
    setAdUnit,
  } = useDFPAds()

  // update the targeting params and the adUnit when pages change
  const updateAdAttributes = useCallback(
    (data, location) => {
      if (!data) return

      let pagetype = ''
      let dataObj: any = {}
      let category = null
      if (location?.pathname === '/') {
        pagetype = 'homepage'
        category = 'homepage'
        dataObj = data.page
      } else if (data.monthlyIssue) {
        pagetype = 'monthly-issue'
        category = 'monthly-issue'
        dataObj = data.monthlyIssue
      } else if (data.article) {
        pagetype = 'article'
        dataObj = data.article
        const categories = dataObj?.categories
          ?.map((c) => c?.handle?.current)
          .filter((i) => typeof i !== 'undefined')
        category = categories?.length ? categories : null
      } else if (data.category) {
        pagetype = 'category'
        dataObj = data.category
        category = dataObj?.handle?.current || null
      } else if (data.shopFront) {
        pagetype = 'shop'
        dataObj = data.shopFront
        category = dataObj?.handle?.current || null
      } else {
        return
      }
      const _targeting = {
        title: dataObj?.handle?.current || null,
        pagetype: pagetype,
        cat: category,
        subcat: dataObj?.subcategory
          ? slugify(dataObj.subcategory, { lower: true })
          : null,
        tags: dataObj?.tags || null,
        sponsored: sponsored,
        env: env,
        skin: skin,
        adtest: getKvAdTest(location),
      }
      console.log('ad-debug targeting', { _targeting })

      setTargeting(
        Object.keys(_targeting)
          .filter((k) => _targeting[k] !== null)
          .reduce((a, k) => ({ ...a, [k]: _targeting[k] }), {}),
      )
    },
    [env, getKvAdTest, setTargeting, skin, sponsored],
  )

  const pageTargetingChange = useCallback(async () => {
    let _adUnit = site
    if (targeting.pagetype === 'article') {
      _adUnit += '/article'
    }
    if (targeting.pagetype === 'monthly-issue') {
      _adUnit += '/monthly-issue'
    }
    if (targeting.pagetype === 'homepage') {
      _adUnit += '/home'
    }
    if (targeting.pagetype === 'category') {
      _adUnit += '/category'
    }
    if (targeting.pagetype === 'shop') {
      _adUnit += '/shop'
    }
    setAdUnit(_adUnit)
  }, [targeting, setAdUnit, site])

  const refreshAds = (googletag) =>
    new Promise<boolean>((resolve) =>
      setTimeout(() => {
        if (googletag?.pubads && googletag?.cmd) {
          createOopAds(googletag, adUnit)
          googletag.pubads().refresh()
          resolve(true)
        } else {
          resolve(false)
        }
      }, 250),
    )

  useEffect(() => {
    if (!adUnit) return
    DFPManager.getGoogletag().then(async (googletag) => {
      let adsRefreshed = false
      for (let i = 0; i < 10; i++) {
        adsRefreshed = await refreshAds(googletag)
        if (adsRefreshed) return
      }
      console.log('Error refreshing ads, max tries reached (10)')
    })
    // eslint-disable-next-line
  }, [adUnit])

  useEffect(() => {
    if (!targeting) return
    pageTargetingChange()
  }, [pageTargetingChange, targeting])

  const contextValue = React.useMemo<DFPProps>(
    () => ({
      targeting,
      adUnit,
      setAdUnit,
      updateAdAttributes,
    }),
    [targeting, adUnit, setAdUnit, updateAdAttributes],
  )
  console.log('ad-debug contextValue', {
    contextValue,
    lazyLoadParams,
    dfpNetworkId: networkId,
  })

  return (
    <DFPContext.Provider value={contextValue}>
      <DFPSlotsProvider
        lazyLoad={lazyLoadParams}
        disableInitialLoad
        dfpNetworkId={networkId}
        singleRequest={false}
        collapseEmptyDivs
        // adUnit={adUnit}
        // autoReload={{ adUnit: true }}
      >
        {children}
      </DFPSlotsProvider>
    </DFPContext.Provider>
  )
}

export const useDFPContext = (): DFPProps =>
  ({ ...React.useContext(DFPContext) } as DFPProps)
