module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.11.0_graphql@16.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","name":"InStyle","short_name":"InStyle","description":"Fashion, Beauty, Culture and Lifestyle","background_color":"#FFFFFF","theme_color":"#000000","display":"minimal-ui","icon":"static/images/icon.png","icons":[{"src":"static/images/icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8c21cee4e622af7491e2f8567a3c3648"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-analytics@5.11.0_gatsby@5.11.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-224024531-1","includeInDevelopment":true,"defaultDataLayer":{"platform":"gatsby"},"head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.11.0_gatsby@5.11.0_webpack@5.88.2/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeSecurityHeaders":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-catch-links@5.11.0_gatsby@5.11.0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.11.0_@swc+core@1.3.78_babel-eslint@10.1.0_esbuild@0.18.20_react-dom@18.2.0_react@18.2.0_typescript@5.1.6/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
