export const FormLabel = {
  baseStyle: {
    fontSize: "md",
    fontWeight: "normal",
    lineHeight: 1,
    mb: 0,
  },
  variants: {
    caps: {
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
  },
  defaultProps: {
    variant: "caps",
  },
}
