import { useTheme, useMediaQuery } from "@chakra-ui/react"
import { useHydration } from "./useHydration"

export const useMedia = () => {
  const { breakpoints } = useTheme()
  const { isHydrated } = useHydration()

  const queries = {
    xs: useMediaQuery(`(max-width: ${breakpoints.xs.replace("rem", "") * 16}px)`)?.[0],
    sm: useMediaQuery(`(min-width: ${breakpoints.xs.replace("rem", "") * 16 + 1}px)`)?.[0],
    md: useMediaQuery(`(min-width: ${breakpoints.sm.replace("rem", "") * 16 + 1}px)`)?.[0],
    lg: useMediaQuery(`(min-width: ${breakpoints.md.replace("rem", "") * 16 + 1}px)`)?.[0],
    xl: useMediaQuery(`(min-width: ${breakpoints.lg.replace("rem", "") * 16 + 1}px)`)?.[0],
  }
  const isXSmall = isHydrated ? queries.xs : true
  const isSmall = isHydrated ? queries.sm : true
  const isMedium = isHydrated ? queries.md : true
  const isLarge = isHydrated ? queries.lg : true
  const isXLarge = isHydrated ? queries.xl : true

  return { isXSmall, isSmall, isMedium, isLarge, isXLarge }
}

export const useMediaMobile = () => {
  const { breakpoints } = useTheme()
  const { isHydrated } = useHydration()

  const query = useMediaQuery(`(max-width: ${breakpoints.xs.replace("rem", "") * 16}px)`)
  const result = isHydrated ? query : [true]
  return result?.[0] ?? result
}

export const useMediaDesktop = () => {
  const { breakpoints } = useTheme()
  const { isHydrated } = useHydration()

  const query = useMediaQuery(`(min-width: ${breakpoints.sm.replace("rem", "") * 16 + 1}px)`)
  const result = isHydrated ? query : [false]
  return result?.[0] ?? result
}
