import React from 'react'
import { useCore } from '../../../hooks/useCore'
import { useMediaDesktop } from '../../../hooks/useMedia'
import NavigationHeaderDesktop from './NavigationHeaderDesktop'
import NavigationHeaderMobile from './NavigationHeaderMobile'

type Props = {
  setMegaActive: any
}

const NavigationHeader: React.FC<Props> = ({ setMegaActive }) => {
  const {
    helpers: { ErrorBoundary, isBrowser },
  } = useCore()

  const isDesktop = useMediaDesktop()

  return isBrowser ? (
    <ErrorBoundary>
      {!isDesktop ? (
        <NavigationHeaderMobile />
      ) : (
        <NavigationHeaderDesktop setMegaActive={setMegaActive} />
      )}
    </ErrorBoundary>
  ) : null
}

export default React.memo(NavigationHeader)
