import React from "react"
import { Box, Flex, Divider, Button, Heading, Image, VStack } from "@chakra-ui/react"
import Link from "../../../components/Link"
import SocialMedia from "../../../components/SocialMedia"
import { useNavigation } from "../../../hooks/useNavigation"
import { useCurrentIssue } from "../../../hooks/useCurrentIssue"

type Props = {
  megaActive: boolean
  setMegaActive: any
}

const NavigationHeaderDesktopMega: React.FC<Props> = ({ megaActive, setMegaActive }) => {
  const { megaMenu: sections } = useNavigation()
  const { megaMenuBtn, openCurrentIssue } = useCurrentIssue()

  return (
    <>
      <Box
        position="absolute"
        top="-1px"
        left="0"
        bg="white"
        visibility={megaActive ? "visible" : "hidden"}
        opacity={megaActive ? "1" : "0"}
        transition="all 0.15s ease"
        transform={megaActive ? "translateY(0)" : "translateY(10px)"}
        width="100%"
        onMouseLeave={() => setMegaActive(false)}
        onClick={() => setMegaActive(false)}
        role="group"
        boxShadow="xl"
        zIndex={3}
      >
        <Divider />
        <Flex padding="16">
          <Flex direction="column" flex="1.5">
            <VStack alignItems="left">
              <Heading
                fontSize={["32px", "40px"]}
                onClick={openCurrentIssue}
                cursor="pointer"
                _hover={{ color: "brand.secondary", textDecoration: "underline" }}
              >
                {megaMenuBtn}
              </Heading>
              <Flex direction="column">
                {sections &&
                  sections[0]?.items.map(({ title, url, order }: { title: string; url: string; order: number }, index: number) => {
                    return (
                      <Heading
                        fontSize={["32px", "40px"]}
                        as={Link}
                        key={`col-1-${index}`}
                        to={url}
                        textDecoration="auto !important"
                        _hover={{ color: "brand.secondary" }}
                        order={order}
                        mt="4"
                      >
                        {title}
                      </Heading>
                    )
                  })}
              </Flex>
            </VStack>
            <SocialMedia color="black" fontSize="12px" fill="none" stroke="black" mt="20" />
          </Flex>
          <Flex direction="column" flex="1" mr="8">
            <VStack alignItems="left">
              <Flex direction="column">
                {sections &&
                  sections[1]?.items.map(({ title, url, order }: { title: string; url: string; order: number }, index: number) => {
                    const isSubscribe = title.toLowerCase() === "subscribe"
                    return (
                      <Box key={`sub-${index}`} order={order} mt="4">
                        {isSubscribe && <Divider mb="4" />}
                        <Heading as={Link} size="h4" fontSize={isSubscribe ? "12px" : "inherit"} to={url} textDecoration="auto !important">
                          {title}
                        </Heading>
                      </Box>
                    )
                  })}
              </Flex>
            </VStack>
          </Flex>

          <Flex direction="column" flex="1" bgColor={(sections && sections[2]?.bgColor?.hex) || "brand.secondary"} p="10">
            {sections && sections[2]?.image.src ? (
              <Image objectFit="cover" src={sections[2].image?.src} alt={sections[2].image?.alt} width="100%" />
            ) : (
              <>
                {sections[2]?.content && (
                  <Flex justifyContent="center" textAlign="center" color="white">
                    {sections[2].content}
                  </Flex>
                )}
                {sections[2]?.btnLink && (
                  <Link
                    title={sections[2].btnLink?.title}
                    to={sections[2].btnLink?.link}
                    textDecoration="none !important"
                    _hover={{ textDecoration: "none" }}
                    mt="8"
                  >
                    <Button variant="outlinedReversed">{sections[2].btnLink?.title}</Button>
                  </Link>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default React.memo(NavigationHeaderDesktopMega)
