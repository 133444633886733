export const Accordion = {
  baseStyle: {
    container: {
      _first: {
        borderTop: "none",
      },
      _last: {
        borderBottom: "none",
      },
    },
    button: {
      fontSize: "12px",
      alignItems: "center",
      textTransform: "uppercase",
      _hover: {
        bg: "none",
        color: "grey.text",
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
    panel: {
      fontSize: "sm",
      textAlign: "center",
    },
  },
}
