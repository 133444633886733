import React from "react"
import { Box } from "@chakra-ui/react"

const Main = ({ children, ...props }: { children: any }) => (
  <Box as="main" className="main-page-container" {...props} mb="24">
    {children}
  </Box>
)

export default React.memo(Main)
