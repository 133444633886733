export const Checkbox = {
  baseStyle: {
    container: {},
    control: {
      borderRadius: "none",
      bg: "brand.greyTertiary",
      border: "1px solid",
      borderColor: "black",
      transition: "all 0.2s ease",
      h: 4,
      w: 4,
      _checked: {
        bg: "black",
        borderColor: "black",
        _hover: {
          bg: "black",
          borderColor: "black",
        },
      },
      _disabled: {
        bg: "grey.dark",
        borderColor: "grey.dark",
        _checked: {
          bg: "grey.dark",
          borderColor: "grey.dark",
        },
      },
      _hover: {
        bg: "transparent",
        borderColor: "grey.text",
        textDecoration: "underline",
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
    icon: {
      color: "white",
      w: 3,
      h: 2,
    },
    label: {
      fontSize: "md",
      ml: 2,
    },
  },
  defaultProps: {
    size: "base",
  },
}
