import { useMemo, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useRoutes } from "./useRoutes"
import { useCore } from "./useCore"

export const useNavigation = () => {
  const { urlResolver } = useRoutes()
  const {
    helpers: { sanityContent },
  } = useCore()
  const { menus } = useStaticQuery<GatsbyTypes.StaticMenuQuery>(graphql`
    query StaticMenu {
      menus: sanitySettingMenus {
        header {
          items: _rawItems(resolveReferences: { maxDepth: 3 })
          handle {
            current
          }
        }
        megaMenu {
          items: _rawItems(resolveReferences: { maxDepth: 4 })
          handle {
            current
          }
        }
        footer {
          items: _rawItems(resolveReferences: { maxDepth: 2 })
          handle {
            current
          }
        }
      }
    }
  `)

  const buildItems = useCallback(
    (items: any) =>
      items?.map((link: any) => {
        const type = link?._type?.replace("navigation", "").toLowerCase()
        const internal = ["link", "sub"].includes(type)
        const item = !internal ? link[type] : false
        const items = buildItems(link?.items) || false
        const resolvedUrl = urlResolver(item)
        const title = link?.title || resolvedUrl?.title
        const url = !internal ? resolvedUrl?.url : link.link || false
        const content = sanityContent(link.content) || null
        const btnLink = link.btnLink || null
        const bgColor = link.backgroundColor || null
        const image = {
          alt: link?.image?.alt || "",
          src: link?.image?.asset?.url || "",
        }
        const order = link?.order
        return {
          type,
          url,
          title,
          content,
          order,
          bgColor,
          btnLink,
          image,
          ...(item && { item }),
          ...(items && { items }),
        }
      }) || [],
    [urlResolver]
  )

  const header = useMemo(() => buildItems(menus?.header?.items), [menus, buildItems])
  const megaMenu = useMemo(() => buildItems(menus?.megaMenu?.items), [menus, buildItems])
  const footer = useMemo(() => buildItems(menus?.footer?.items), [menus, buildItems])

  return { header, megaMenu, footer }
}
