import React, { useReducer } from "react"

type ContextProps = {
  state: {
    mobileMenu: boolean
    activeSearch: boolean
    activeSubscribe: boolean
    currentIssueSlider: boolean
  }
  dispatch: React.Dispatch<any>
}

export const AppContext = React.createContext<ContextProps | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const initialValues = {
    mobileMenu: false,
    activeSearch: false,
    activeSubscribe: false,
    currentIssueSlider: false,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "initial":
        return { ...state, ...initialValues }
      case "mobileMenu":
        return {
          ...state,
          mobileMenu: action.payload,
          currentIssueSlider: false,
          activeSearch: false,
          activeSubscribe: false,
        }
      case "search":
        return {
          ...state,
          activeSearch: action.payload,
          currentIssueSlider: false,
          mobileMenu: false,
          activeSubscribe: false,
        }
      case "subscribe":
        return {
          ...state,
          activeSubscribe: action.payload,
          currentIssueSlider: false,
          activeSearch: false,
          mobileMenu: false,
        }
      case "currentIssueSlider":
        return {
          ...state,
          currentIssueSlider: action.payload,
          activeSubscribe: false,
          activeSearch: false,
          mobileMenu: false,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues)

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): ContextProps => ({ ...React.useContext(AppContext) } as ContextProps)
