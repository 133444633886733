import { useState, useCallback } from 'react'
import { CONFIG } from '../config'

export const useFunctions = (initialData = {}) => {
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])

  const handleChange = ({
    target: { type, name, value, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const callFunction = useCallback(async (name: string, data: any) => {
    try {
      setLoading(true)
      setErrors([])

      const response = await fetch(
        `https://${CONFIG.INSTYLE_API_URL}/api/subscribe`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      )
      const body = await response.json()

      setLoading(false)
      return { ...body }
    } catch (err) {
      setLoading(false)
      setErrors([(err as Error).message])
      return { status: 'error', body: (err as Error).message }
    }
  }, [])

  return { callFunction, loading, errors, data, handleChange }
}
