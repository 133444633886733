import { createBreakpoints } from "@chakra-ui/theme-tools"

export const breakpoints = createBreakpoints({
  xs: "48rem",
  sm: "64rem",
  md: "90rem",
  lg: "100rem",
  xl: "120rem",
})

/* 
  xs: "48rem" - 768px
  sm: "64rem" - 1024px
  md: "90rem" - 1440px
  lg: "100rem" - 1600px
  xl: "120rem" - 1920px
*/
