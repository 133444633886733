import React from "react"
import { FormControl, FormErrorMessage, Input, Button, Stack, Box, Checkbox } from "@chakra-ui/react"
import { useAppContext } from "../../providers/app"
import { useForm } from "../../hooks/useForm"

const SubscribeForm: React.FC = () => {
  const { submitForm, handleChange, data, loading, errors } = useForm()
  const { state, dispatch } = useAppContext()

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    const response = await submitForm(data)
    if (response)
      dispatch({
        type: "subscribe",
        payload: !state.activeSubscribe,
      })
  }

  const formattErrors = (error: string) => {
    if (error.includes("international date")) {
      return "Please format the date as (DD/MM/YYYY)"
    }
    return "Please check all fields are filled in"
  }

  return (
    <Box as="form" onSubmit={handleSubmit} mb={8} borderRadius="initial">
      <Stack spacing={8}>
        <FormControl id="firstname" onChange={handleChange} isRequired>
          <Input name="firstname" value={data?.firstname} placeholder="First Name" />
        </FormControl>
        <FormControl id="lastname" onChange={handleChange} isRequired>
          <Input name="lastname" value={data?.lastname} placeholder="Last Name" />
        </FormControl>
        <FormControl id="email" onChange={handleChange} isRequired>
          <Input name="email" value={data?.email} type="email" placeholder="Email Address" />
        </FormControl>
        <FormControl id="dob" onChange={handleChange}>
          <Input name="dob" value={data?.dob} type="date" placeholder="Date of birth" />
        </FormControl>
        <FormControl>
          <Checkbox isRequired>By subscribing, you agree to receive emails according to our Privacy Policy.</Checkbox>
          {/* TODO: add to sanity */}
        </FormControl>
        <Button variant="filled" type="submit" isDisabled={loading} isLoading={loading}>
          Subscribe
        </Button>
        {errors?.length > 0 && (
          <FormControl id="error" isInvalid>
            {errors?.map((error, index) => (
              <FormErrorMessage key={index}>{formattErrors(error)}</FormErrorMessage>
            ))}
          </FormControl>
        )}
      </Stack>
    </Box>
  )
}

export default SubscribeForm
