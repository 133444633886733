/* eslint react/no-children-prop: 0 */
import React, { useState } from "react"
import { Box, Input, InputRightElement, InputGroup } from "@chakra-ui/react"
import Icon from "../../components/Icon"
import { useMediaMobile } from "../../hooks/useMedia"

type Props = {
  onSubmit: any
  location?: any
  placeholder?: any
  color?: any
  showClear?: boolean
}

const SearchInput: React.FC<Props> = ({ onSubmit, location, placeholder, color, showClear = true }) => {
  const isMobile = useMediaMobile()
  const [input, setInput] = useState(location?.state?.search || "")

  const handleChange = (e: any) => {
    setInput(e.target.value)
  }

  const handleClear = () => {
    setInput("")
  }

  return (
    <InputGroup>
      <Input
        variant="search"
        value={input}
        onChange={e => handleChange(e)}
        onKeyDown={e => onSubmit(e)}
        placeholder={placeholder}
        color={color}
      />
      {showClear && (
        <InputRightElement
          children={
            <Box
              onClick={() => {
                handleClear()
              }}
              cursor="pointer"
            >
              <Icon name="cross" title="cross" height={25} width={25} stroke="#BF2338" style={{ marginTop: isMobile ? "-10px" : "30px" }} />
            </Box>
          }
        />
      )}
    </InputGroup>
  )
}

export default SearchInput
