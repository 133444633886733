import React from "react"
import config from "../../config.default"

type ContextProps = Config

export const ConfigContext = React.createContext<ContextProps | undefined>(undefined)

export const ConfigProvider: React.FC = ({ children }) => {
  const activeConfig = React.useMemo<ContextProps>(() => config as Config, [])

  const contextValue = React.useMemo<ContextProps>(() => activeConfig, [activeConfig])

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>
}

export const useConfigContext = (): ContextProps => ({ ...React.useContext(ConfigContext) } as ContextProps)
