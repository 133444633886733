export const styles = {
  global: {
    ":root": {
      "--swiper-theme-color": "black !important",
    },
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      bg: "white",
      color: "brand.black",
    },
    a: {
      color: "brand.black",
      transition: "all .4s ease",
      _hover: {
        color: "brand.secondary",
        textDecoration: "none !important",
      },
      _focus: {
        boxShadow: "unset",
      },
    },
  },
}
