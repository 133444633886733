import React from "react"
import { Button, Text } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import { useAppContext } from "../../providers/app"
import { useMediaMobile } from "../../hooks/useMedia"

type props = {
  btnTextOverride?: string
}

const SubscribeButton: React.FC<props> = ({ btnTextOverride }) => {
  const { social } = useStaticQuery<GatsbyTypes.StaticSubscribeQuery>(graphql`
    query StaticSubscribe {
      social: sanitySettingSocial {
        additionalSubscribeText
        additionalSubscribeBtn
      }
    }
  `)

  const { dispatch } = useAppContext()
  const isMobile = useMediaMobile()

  const onClick = () => {
    dispatch({
      type: "subscribe",
      payload: true,
    })
  }

  return (
    <>
      {!isMobile && (
        <Text color="white" fontSize="md">
          {social?.additionalSubscribeText}
        </Text>
      )}
      <Button
        variant="outlinedReversed"
        mt="4"
        as={"button"}
        // to="https://subscribe.instyleaustralia.com.au"
        onClick={onClick}
        fontSize="sm"
        width="full"
      >
        {btnTextOverride || social?.additionalSubscribeBtn}
      </Button>
    </>
  )
}

export default React.memo(SubscribeButton)
