import React from "react"
import { Box, IconButton } from "@chakra-ui/react"
import Icon from "../../../components/Icon"
import { useAppContext } from "../../../providers/app"

const MobileMenuIcon: React.FC = () => {
  const { state, dispatch } = useAppContext()

  const onToggle = () => {
    dispatch({
      type: "mobileMenu",
      payload: !state.mobileMenu,
    })
  }

  return (
    <Box alignSelf="center" className="mobile-menu-icon">
      <IconButton variant="icon" aria-label="Menu" icon={<Icon name="hamburger" width={25} height={25} />} size="sm" onClick={onToggle} />
    </Box>
  )
}

export default React.memo(MobileMenuIcon)
