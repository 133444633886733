import { fonts } from "src/theme/fonts"

export const Input = {
  baseStyle: {
    field: {
      border: "1px solid",
      borderColor: "brand.greySecondary",
      color: "brand.primary",
      fontFamily: fonts.body,
      backgroundColor: "brand.greyTertiary",
      _placeholder: {
        textTransform: "uppercase",
        fontSize: "md",
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
        borderColor: "brand.primary",
        color: "brand.primary",
        backgroundColor: "brand.white",
      },
      _hover: {
        borderColor: "brand.primary",
        _placeholder: {
          color: "brand.primary",
        },
      },
      _invalid: {
        color: "brand.secondary",
        borderColor: "brand.secondary",
        _placeholder: {
          color: "brand.secondary",
        },
      },
    },
  },
  variants: {
    base: {
      field: {
        borderRadius: "none",
        fontSize: "md",
        _hover: {
          boxShadow: "none",
        },
      },
    },
    search: {
      field: {
        borderRadius: "none",
        fontSize: ["28px", "48px"],
        height: "100%",
        paddingBottom: ["10px", "20px"],
        background: "transparent",
        border: "unset",
        borderBottom: "1px solid #BF2338",
        backgroundColor: "transparent",
        fontFamily: fonts.heading,
        px: "0",
        _placeholder: {
          fontFamily: fonts.heading,
          fontSize: ["28px", "48px"],
          color: "inherit",
          textTransform: "capitalize",
        },
        _focus: {
          background: "transparent",
          borderBottom: "1px solid #BF2338",
          color: "inherit",
        },
        _hover: {
          background: "transparent",
          borderBottom: "1px solid #BF2338",
          boxShadow: "none",
          color: "inherit",
          _placeholder: {
            color: "inherit",
          },
        },
      },
    },
  },
  defaultProps: {
    size: "lg",
    variant: "base",
  },
}
