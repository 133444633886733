import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Text, Box, Container, useTheme } from "@chakra-ui/react"

const Copyright: React.FC = () => {
  const {
    colors: {
      brand: { greyPrimary },
    },
  } = useTheme()

  const { global } = useStaticQuery<GatsbyTypes.StaticCopyrightQuery>(graphql`
    query StaticCopyright {
      global: sanityTemplateGlobal {
        addtionalCopyrightBody
        addtionalCopyrightTitle
      }
    }
  `)
  const { addtionalCopyrightBody, addtionalCopyrightTitle } = global

  return (
    <Box bgColor="white" color="black" py="16">
      <Container maxW="container.xl" textAlign="center">
        <Text fontSize="sm" textTransform="uppercase" mb="2">
          {addtionalCopyrightTitle}
        </Text>
        <Text fontSize="sm" maxW="900px" mx="auto" color={greyPrimary}>
          {addtionalCopyrightBody}
        </Text>
      </Container>
    </Box>
  )
}

export default React.memo(Copyright)
