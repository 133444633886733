import { useCore } from "./useCore"

export const useRoutes = () => {
  const {
    helpers: { getUrlParameter, setUrlParameter },
  } = useCore()

  const urlResolver = (source: any, route = "") => {
    const preview = process.env.GATSBY_PREVIEW === "true" || false
    const urlParts = []
    const type = source?._type || source?.document?._type
    const item = source?.[type] || source?.document || source
    const external = source?.external || false
    const url = item?.url || source?.link || ""
    const title = source?.title || item?.title || ""
    const handle = item?.meta?.canonicalUrl || item?.handle?.current || item?.handle || ""
    const category = (source?.categories && source?.categories[0] && source?.categories[0].handle?.current) || ""

    if (url?.length) urlParts.push(url)
    if (route?.length && !url?.length) urlParts.push(route)
    if (category !== "" && !preview) urlParts.push(`/${category}`)
    if (handle?.length) urlParts.push(`/${handle}`)

    const completeUrl = urlParts?.[0] !== "/home" ? urlParts.join("") || "/" : "/"

    return {
      title,
      url: completeUrl,
      external,
    }
  }

  return {
    getUrlParameter,
    setUrlParameter,
    urlResolver,
  }
}
