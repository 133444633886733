import React from "react"
import Logo from "../../static/images/logo.svg"
import AltLogo from "../../static/images/logo_alt.svg"
import Chevron from "../../static/icons/chevron.svg"
import ChevronReverse from "../../static/icons/chevronReverse.svg"
import ChevronAlt from "../../static/icons/chevron_alt.svg"
import Hamburger from "../../static/icons/hamburger.svg"
import Cross from "../../static/icons/cross.svg"
import Tick from "../../static/icons/tick.svg"
import Magnify from "../../static/icons/magnify.svg"
import Instagram from "../../static/icons/social_insta.svg"
import Pinterest from "../../static/icons/social_pinterest.svg"
import Facebook from "../../static/icons/social_fb.svg"
import Youtube from "../../static/icons/social_youtube.svg"
import Twitter from "../../static/icons/social_twitter.svg"
import Play from "../../static/icons/play.svg"
import Minus from "../../static/icons/minus.svg"
import Plus from "../../static/icons/plus.svg"
import Mute from "../../static/icons/mute.svg"
import UnMute from "../../static/icons/unmute.svg"

type Props = {
  name: string
  title?: string
  width?: number | string
  height?: number | string
  fill?: string
  stroke?: string
  style?: any
}

type Icon = {
  id: string
  url: string
  viewBox: string
}

type Icons = {
  [key: string]: Icon
}

const Icon: React.FC<Props> = ({ name, title, width = 20, height = 20, fill, stroke, style }) => {
  const icons: Icons = {
    logo: Logo,
    logoAlt: AltLogo,
    chevron: Chevron,
    chevronReverse: ChevronReverse,
    chevronAlt: ChevronAlt,
    hamburger: Hamburger,
    cross: Cross,
    tick: Tick,
    magnify: Magnify,
    instagram: Instagram,
    pinterest: Pinterest,
    facebook: Facebook,
    youtube: Youtube,
    play: Play,
    twitter: Twitter,
    minus: Minus,
    plus: Plus,
    mute: Mute,
    unmute: UnMute,
  }

  const icon = icons[name]

  return icon ? (
    <svg viewBox={icon.viewBox} height={height} width={width} id={icon.id} fill={fill} stroke={stroke} style={style}>
      <title id={`${icon.id}Title`}>{title || name}</title>
      <use xlinkHref={icon.url} />
    </svg>
  ) : (
    <p>[{title}]</p>
  )
}

export default React.memo(Icon)
