import { useState, useEffect } from "react"
import { throttle } from "../utils/throttle"
interface WindowSizeProps {
  width: number | undefined
  height: number | undefined
}

const isBrowser = typeof window !== "undefined"

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSizeProps>({
    width: isBrowser ? window.innerWidth : undefined,
    height: isBrowser ? window.innerHeight : undefined,
  })

  useEffect(() => {
    const handleResize = throttle(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }, 1000)

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return windowSize
}
