import React from 'react'
import { Stack, Box, Text } from '@chakra-ui/react'
import { useNavigation } from '../../../hooks/useNavigation'
import Link from '../../../components/Link'
import Brand from '../../../components/Brand'

const NavigationFooterDesktop: React.FC = () => {
  const { footer: items } = useNavigation()

  return (
    <Stack flexDirection="column" spacing={['6']} flex="1">
      <Box mb="30px">
        <Brand width={200} fill="white" />
      </Box>

      {items.map(
        (
          {
            type,
            title,
            url,
            items,
          }: { type: string; title: string; url: string; items: any },
          index: number,
        ) =>
          type !== 'sub' ? (
            <Text
              as={Link}
              key={index}
              to={url}
              textTransform="uppercase"
              fontSize="sm"
              color="white"
            >
              {title}
            </Text>
          ) : (
            <Box key={index} minW="130px">
              <Text size="md" mb="4" color="white" fontWeight={600}>
                {title}
              </Text>
              {!!items?.length && (
                <Stack>
                  {items.map(
                    (
                      { title, url }: { title: string; url: string },
                      index: number,
                    ) => (
                      <Text as={Link} key={index} to={url} color="white">
                        {title}
                      </Text>
                    ),
                  )}
                </Stack>
              )}
            </Box>
          ),
      )}
    </Stack>
  )
}

export default React.memo(NavigationFooterDesktop)
