import { fonts } from "src/theme/fonts"

export const Heading = {
  baseStyle: {
    color: "brand.primary",
    fontFamily: fonts.heading,
    fontWeight: "normal",
    transition: "color .4s ease",
  },
  sizes: {
    h1: {
      fontSize: ["32", "48"],
      lineHeight: ["38.4px", "57.6px"],
    },
    h2: {
      fontSize: ["24", "28"],
      lineHeight: ["28.8px", "33.6px"],
    },
    h3: {
      fontSize: "18",
      lineHeight: "21.6px",
    },
    h4: {
      fontSize: ["14", "16"],
      lineHeight: ["18.1px", "20.69px"],
      textTransform: ["uppercase"],
      fontFamily: fonts.body,
      letterSpacing: ["1.12px", "1.6px"],
    },
    h5: {
      fontSize: ["11", "14"],
      lineHeight: ["14.22px", "18.1px"],
      textTransform: ["uppercase"],
      fontFamily: fonts.body,
      letterSpacing: ["1.12px", "1.6px"],
    },
  },
  variants: {
    italic: {
      fontFamily: fonts.headingItalic,
    },
    categoryHeading: {
      fontSize: ["76.35px", "100px", "130px"],
      fontFamily: fonts.callout,
    },
    categoryHeadingXL: {
      fontSize: ["96.35px", "125px", "194px"],
      fontFamily: fonts.callout,
    },
    categoryHeadingSM: {
      fontSize: ["32px", "40px", "40px"],
      fontFamily: fonts.callout,
    },
    categoryHeadingMD: {
      fontSize: ["44px", "96px", "96px"],
      fontFamily: fonts.callout,
    },
  },
}
