import React from "react"
import { AppProvider } from "./app"
import { ThemeProvider } from "./theme"
import { ConfigProvider } from "./config"
import { TrackingProvider } from "./tracking"
import { ParallaxProvider } from "react-scroll-parallax"
import { DFPProvider } from "./dfp"

const Providers: React.FC = ({ children }) => (
  <ConfigProvider>
    <AppProvider>
      <DFPProvider>
        <TrackingProvider>
          <ThemeProvider>
            <ParallaxProvider>{children}</ParallaxProvider>
          </ThemeProvider>
        </TrackingProvider>
      </DFPProvider>
    </AppProvider>
  </ConfigProvider>
)

export default Providers
