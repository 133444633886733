export const NEW_ISSUE_DESKTOP_WIDTH = 1512
export const NEW_ISSUE_MOBILE_WIDTH = 448

export const NEW_ISSUE_GOOGLE_ADS_MOBILE_HEIGHT = 336

export const NEW_ISSUE = {
  GOOGLE_ADS_SIZES: {
    DESKTOP: [[NEW_ISSUE_DESKTOP_WIDTH, 850]],
    MOBILE: [[NEW_ISSUE_MOBILE_WIDTH, NEW_ISSUE_GOOGLE_ADS_MOBILE_HEIGHT]],
  },
}

export const GOOGLE_ADS_MONTHLY_ISSUE = [
  {
    viewport: [NEW_ISSUE_MOBILE_WIDTH + 1, 0],
    sizes: NEW_ISSUE.GOOGLE_ADS_SIZES.DESKTOP,
  },
  {
    viewport: [0, 0],
    sizes: NEW_ISSUE.GOOGLE_ADS_SIZES.MOBILE,
  },
]
