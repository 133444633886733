import { useRef, useLayoutEffect } from "react"

import { useCore } from "./useCore"

const GetScrollPosition = ({ element, useWindow }: any) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow ? { x: window.scrollX, y: window.scrollY } : { x: position.left, y: position.top }
}

export const useScrollPosition = (effect: any, deps: any = null, element = null, useWindow = false, wait = 0) => {
  const position = useRef(GetScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = GetScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, deps)
}
