import React from "react"
import { Helmet } from "react-helmet"
import { useMeta } from "../hooks/useMeta"
import { useConfigContext } from "../providers/config"

import type { Location } from "../../types/global"

type Props = {
  breadcrumbs?: Array<any>
  data: any
  location: Location
}

const Meta: React.FC<Props> = ({ breadcrumbs = [], data, location }) => {
  const { getData, getLanguages, getSchemas, getTags, getTracking } = useMeta()
  const {
    settings: { routes },
  } = useConfigContext()
  const url = location?.pathname
  const languages = getLanguages(url)
  const metadata = getData({
    url,
    data,
    routes,
    breadcrumbs,
    template: data?.template?.metadata,
    language: languages?.find(({ primary }) => primary)?.language?.split("_")?.[0] || "",
  })
  const metatags = getTags(metadata)
  const schemas = getSchemas(metadata)
  const tracking = getTracking()

  return (
    <>
      <Helmet htmlAttributes={{ lang: metadata?.metaLang }} title={metadata?.title} meta={metatags}>
        <link key="home" href={metadata?.siteURL} rel="home" />
        <link key="canonical" href={metadata?.canonical} rel="canonical" />
        {languages?.map(({ key, href, hrefLang, rel }, index) => (
          <link key={key?.toString() + index} href={href} hrefLang={hrefLang} rel={rel} />
        ))}
        {schemas.map((schema, index) => (
          <script type="application/ld+json" key={index}>
            {JSON.stringify(schema)}
          </script>
        ))}
      </Helmet>
      {tracking.map(script => script)}
    </>
  )
}

export default React.memo(Meta)
