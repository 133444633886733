import { useStaticQuery, graphql } from "gatsby"

export const useMasthead = () => {
  const { data } = useStaticQuery<GatsbyTypes.StaticMastheadsQuery>(graphql`
    query StaticMastheads {
      data: sanitySettingMasthead {
        color {
          hex
          alpha
        }
      }
    }
  `)

  return {
    color: data?.color?.hex,
  }
}
