import React from 'react'
import Link from '../components/Link'
import SparkleInstyle from '../../static/images/sparkle-logo-transparent-400-96.gif'
import { Image } from '@chakra-ui/react'
import Icon from '../components/Icon'

type Props = {
  fill?: string
  style?: any
  width?: number | string
  height?: number | string
}

const Brand: React.FC<Props> = ({ fill, style, width, height }) => {
  if (process.env.FEATURE_FLAG_SPARKLE_DIAMONDS === 'true') {
    return (
      <Link to="/" style={style}>
        <Image
          src={SparkleInstyle}
          alt="InStyle Magazine"
          w={width || 'auto'}
          h={height || 'auto'}
          fill={fill}
        />
      </Link>
    )
  }
  return (
    <Link to="/" style={style}>
      <Icon
        name="logoAlt"
        width={width || '100%'}
        height={height || '100%'}
        title="InStyle Magazine"
        fill={fill}
      />
    </Link>
  )
}

export default React.memo(Brand)
