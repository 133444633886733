import { fonts } from "src/theme/fonts"

export const Select = {
  baseStyle: {
    field: {
      border: "1px solid",
      borderColor: "brand.secondary",
      color: "brand.greyPrimary",
      fontFamily: fonts.body,
      _focus: {
        boxShadow: "none",
        outline: "none",
        borderColor: "brand.primary",
        color: "brand.primary",
        background: "brand.white",
      },
      _hover: {},
      _invalid: {
        color: "brand.secondary",
        borderColor: "brand.secondary",
      },
    },
  },
  sizes: {
    lg: {
      field: {
        fontSize: "sm",
      },
    },
  },
  variants: {
    base: {
      field: {
        fontSize: "md",
        borderRadius: "none",
        borderColor: "brand.greySecondary",
        border: "1px solid",
      },
    },
  },
  defaultProps: {
    size: "lg",
    variant: "base",
  },
}
