import React, { useState, useMemo } from 'react'
import { AdSlot } from 'react-dfp'
import { useDFPAds } from '../../hooks/useDFPAds'
import { Box, Center } from '@chakra-ui/react'
import { useDFPContext } from '../../providers/dfp'

type DFPAdProps = {
  slotId: string
  position: number | 'footer'
  displaySizes?: any
  isSticky?: boolean
  type:
    | 'header'
    | 'billboard'
    | 'sidebar'
    | 'featarticle'
    | 'midarticle'
    | 'outstream'
    | 'monthly-issue'
  py?: any
  pb?: any
  onDisplay?: (display: boolean) => void
}

const DFPAd: React.FC<DFPAdProps> = ({
  slotId,
  position,
  type,
  isSticky,
  displaySizes,
  py,
  pb,
  onDisplay,
}) => {
  const { defaultSizes, sizeMap } = useDFPAds()
  console.log('ad-debug defaultSizes', { type, defaultSizes, sizeMap })
  const sizes = displaySizes || defaultSizes[type]
  const [display, setDisplay] = useState<string>('flex')
  const [height, setHeight] = useState<string>(sizes[1] + 'px')
  const { adUnit, targeting } = useDFPContext()
  // hide the ad completely if there is some kind of non-delivery
  // or adjust height if it's different to the default
  const adjustDisplay = (eventData) => {
    const adDidDisplay = eventData?.event?.size?.length
    console.log('adDidDisplay', adDidDisplay)
    if (eventData?.slotId?.includes('outstream')) {
      setHeight(eventData?.event?.size[1] + 'px')
      setDisplay('block')
      if (onDisplay) {
        onDisplay(true)
      }
    } else if (adDidDisplay) {
      setHeight(eventData?.event?.size[1] + 'px')
      setDisplay('flex')
      if (onDisplay) {
        onDisplay(true)
      }
    } else {
      setDisplay('none')
      if (onDisplay) {
        onDisplay(false)
      }
    }
  }

  const targets = useMemo(() => {
    if (!targeting) return null
    const t = Object.keys(targeting)
      .filter((k) => targeting[k] !== null)
      .reduce((a, k) => ({ ...a, [k]: targeting[k] }), {})
    return { pos: position, ...t }
  }, [targeting, position])

  if (!adUnit || !targeting || !position || !slotId || !targets) return null

  if (slotId.includes('monthlyissue')) {
    console.log('ad-debug monthlyissue targets', {
      slotId,
      targets,
      sizes,
      sizeMap: sizeMap[type],
      adUnit,
    })
    return (
      <AdSlot
        adUnit={adUnit}
        slotId={slotId}
        sizes={sizes}
        sizeMapping={sizeMap[type]}
        targetingArguments={targets}
        onSlotRender={adjustDisplay}
      />
    )
  }
  return slotId.includes('outstream') ? (
    <Box as="div" display={'block'} className="ad">
      <AdSlot
        adUnit={adUnit}
        slotId={slotId}
        sizes={sizes}
        sizeMapping={sizeMap[type]}
        targetingArguments={targets}
        onSlotRender={adjustDisplay}
      />
    </Box>
  ) : (
    <Center
      h={height}
      maxW={'100%'}
      display={display}
      my={py}
      pb={pb}
      className="ad"
      sx={{
        position: isSticky ? 'sticky' : false,
        top: isSticky ? '0' : false,
      }}
    >
      {console.log('%cDFPAd.tsx line:60 eventData', 'color: #007acc;', slotId)}
      <AdSlot
        adUnit={adUnit}
        slotId={slotId}
        sizes={sizes}
        sizeMapping={sizeMap[type]}
        targetingArguments={targets}
        onSlotRender={adjustDisplay}
      />
    </Center>
  )
}

export default DFPAd
