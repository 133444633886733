import * as store from "store"
import React from "react"
import * as node_1 from "domhandler/lib/node"
import * as ReactErrorBoundary from "react-error-boundary"
import BlockContentToReact from "@sanity/block-content-to-react"
import HTMLReactParser from "html-react-parser"
import gatsby from "gatsby"

export const useCore = () => {
  const isBrowser = typeof window !== "undefined"
  const isDomReady =
    isBrowser &&
    (navigator === null || navigator?.userAgent) &&
    !navigator.userAgent.match(
      /nux.*oto\sG|x11.*fox\/54|x11.*ome\/39|x11.*ome\/62|oid\s6.*1.*xus\s5.*MRA58N.*ome|JWR66Y.*ome\/62|woobot|speed|ighth|tmetr|eadle/i
    )
  const isDevelopment = (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development") === "development"
  const metaTags = metadata => {
    var _a, _b, _c, _d, _e, _f
    return [
      { name: "description", content: metadata.description },
      {
        name: "image",
        content: (_a = metadata === null || metadata === void 0 ? void 0 : metadata.image) === null || _a === void 0 ? void 0 : _a.url,
      },
      { property: "og:title", content: metadata === null || metadata === void 0 ? void 0 : metadata.title },
      { property: "og:description", content: metadata.description },
      { property: "og:type", content: metadata === null || metadata === void 0 ? void 0 : metadata.type },
      {
        property: "og:image",
        content: (_b = metadata === null || metadata === void 0 ? void 0 : metadata.image) === null || _b === void 0 ? void 0 : _b.url,
      },
      {
        property: "og:image:alt",
        content:
          (_c = metadata === null || metadata === void 0 ? void 0 : metadata.image) === null || _c === void 0 ? void 0 : _c.description,
      },
      { property: "og:locale", content: metadata.lang },
      { property: "og:site_name", content: metadata === null || metadata === void 0 ? void 0 : metadata.siteName },
      { property: "og:url", content: metadata === null || metadata === void 0 ? void 0 : metadata.pageUrl },
      { property: "fb:app_id", content: metadata === null || metadata === void 0 ? void 0 : metadata.facebookAppID },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:creator", content: metadata === null || metadata === void 0 ? void 0 : metadata.twitterUser },
      { name: "twitter:title", content: metadata === null || metadata === void 0 ? void 0 : metadata.title },
      { name: "twitter:url", content: metadata === null || metadata === void 0 ? void 0 : metadata.pageUrl },
      { name: "twitter:description", content: metadata.description },
      {
        name: "twitter:image",
        content: (_d = metadata === null || metadata === void 0 ? void 0 : metadata.image) === null || _d === void 0 ? void 0 : _d.url,
      },
      {
        name: "twitter:image:alt",
        content:
          (_e = metadata === null || metadata === void 0 ? void 0 : metadata.image) === null || _e === void 0 ? void 0 : _e.description,
      },
      { name: "robots", content: metadata === null || metadata === void 0 ? void 0 : metadata.robots },
      ...(((_f = metadata === null || metadata === void 0 ? void 0 : metadata.googleSiteVerifications) === null || _f === void 0
        ? void 0
        : _f.map(content => ({ name: "google-site-verification", content }))) || []),
    ]
  }

  const schemaOrg = ({ address = {}, siteName, siteImage, siteURL, socialProfiles }) => ({
    "@context": "http://schema.org",
    "@type": "Organization",
    url: siteURL,
    name: siteName,
    address,
    logo: siteImage,
    sameAs: socialProfiles,
  })

  const schemaPerson = ({ name }) => ({
    "@type": "Person",
    name: name || "",
  })

  const schemaWebSite = ({ title, description, metaLang, author, url, siteURL, searchURL }) => ({
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: url,
    inLanguage: metaLang,
    mainEntityOfPage: siteURL,
    description: description,
    name: title,
    author: (0, schemaPerson)({ name: author }),
    creator: (0, schemaPerson)({ name: author }),
    publisher: (0, schemaPerson)({ name: author }),
    copyrightYear: new Date().getFullYear(),
    copyrightHolder: (0, schemaPerson)({ name: author }),
    potentialAction: {
      "@type": "SearchAction",
      target: `${searchURL}?q={query}`,
      "query-input": "required name=query",
    },
  })

  const schemaBreadcrumbs = ({ listItems }) => ({
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement: listItems,
  })

  const schemaContent = metadata =>
    ((metadata === null || metadata === void 0 ? void 0 : metadata.schema) &&
      (metadata === null || metadata === void 0 ? void 0 : metadata.schema(metadata))) ||
    (0, schemaWebSite)(metadata)

  const renderTrackingScripts = (html, options) => {
    const newHtml = html.replace(/<script>/, `<script key="${options.placement}">`)
    const returnScript =
      isDomReady &&
      document.getElementsByClassName(`${options.id}-${options.placement}`).length < 1 &&
      (0, HTMLReactParser)(newHtml, {
        replace: domNode => {
          if (domNode instanceof node_1.Element && domNode.attribs) {
            if (domNode.type === `script`) {
              let script = document.createElement(`script`)
              if (domNode.attribs.src) script.src = domNode.attribs.src
              // @ts-ignore
              if (domNode.children.length) script.innerHTML = domNode.children[0].data
              script.id = `${options.id}-${options.placement}-${Math.random().toString(36).substring(9)}`
              script.className = `${options.id}-${options.placement}`
              script.type = `text/javascript`
              script.async = true
              script.defer = true
              if (options.placement === `head`) document.head.appendChild(script)
              if (options.placement === `body`) document.body.prepend(script)
              if (options.placement === `foot`) document.body.appendChild(script)
              console.log("options", options)
              return React.createElement(React.Fragment, { key: options.placement })
            }
          }
          return domNode
        },
      })
    return returnScript
  }

  const ErrorBoundary = ({ children }) =>
    isBrowser
      ? React.createElement(
          ReactErrorBoundary.ErrorBoundary,
          {
            fallbackRender: ({ error, resetErrorBoundary }) =>
              isDevelopment
                ? React.createElement(
                    "div",
                    { role: `alert`, style: { border: `3px #aaa dashed`, padding: `1rem` } },
                    React.createElement("pre", null, React.createElement("strong", null, "\uD83D\uDC1B Component error")),
                    React.createElement("pre", { style: { background: `#eee`, marginTop: `1rem`, padding: `1rem` } }, error.message),
                    React.createElement(
                      "button",
                      { onClick: resetErrorBoundary, style: { background: `black`, color: `white`, marginTop: `1rem`, padding: `1rem` } },
                      "Reload Component"
                    )
                  )
                : React.createElement(React.Fragment, null),
            onError: error => console.error(error.message),
          },
          React.createElement(React.Suspense, { fallback: false }, children)
        )
      : React.createElement(React.Fragment, null, children)

  const renderCode = html => {
    const elementId = Math.random().toString(36).substring(9)
    return (0, HTMLReactParser)(html, {
      replace: domNode => {
        if (domNode instanceof node_1.Element && domNode.attribs) {
          if (domNode.type === `script`) {
            let script = document.createElement(`script`)
            if (domNode.attribs.src) script.src = domNode.attribs.src
            // @ts-ignore
            if (domNode.children.length) script.innerHTML = domNode.children[0].data
            script.id = `code-${elementId}`
            script.className = `code-${elementId}`
            script.type = `text/javascript`
            script.async = true
            script.defer = true
            document.body.prepend(script)
            return React.createElement(React.Fragment, { key: elementId })
          }
        }
        return domNode
      },
    })
  }

  const serializers = {
    marks: {
      document: ({ children, mark }) => {
        let _a, _b
        return React.createElement(
          gatsby.Link,
          {
            to: ((_a = mark === null || mark === void 0 ? void 0 : mark.document) === null || _a === void 0 ? void 0 : _a.link) || "",
            title: (_b = mark === null || mark === void 0 ? void 0 : mark.document) === null || _b === void 0 ? void 0 : _b.title,
          },
          children
        )
      },
      link: ({ children, mark }) => {
        let _a, _b, _c
        return React.createElement(
          "a",
          {
            href: (_a = mark === null || mark === void 0 ? void 0 : mark.link) === null || _a === void 0 ? void 0 : _a.link,
            title: (_b = mark === null || mark === void 0 ? void 0 : mark.link) === null || _b === void 0 ? void 0 : _b.title,
            target: ((_c = mark === null || mark === void 0 ? void 0 : mark.link) === null || _c === void 0 ? void 0 : _c.external)
              ? "_blank"
              : "",
          },
          children
        )
      },
      color: ({ children, mark }) =>
        React.createElement("span", { style: { color: mark === null || mark === void 0 ? void 0 : mark.hex } }, children),
    },
    types: {
      code: ({ node }) => (0, renderCode)(node.code),
      image: ({ node }) => {
        let _a
        return React.createElement("img", {
          src: (_a = node === null || node === void 0 ? void 0 : node.asset) === null || _a === void 0 ? void 0 : _a.url,
        })
      },
      customImage: ({ node }) => {
        let _a
        return React.createElement("img", {
          alt: node === null || node === void 0 ? void 0 : node.alt,
          src: (_a = node === null || node === void 0 ? void 0 : node.asset) === null || _a === void 0 ? void 0 : _a.url,
        })
      },
    },
  }

  const sanityContent = (content, customSerializers = null) =>
    content
      ? React.createElement(BlockContentToReact, {
          className: "rte",
          blocks: content,
          serializers: customSerializers || serializers,
        })
      : content

  return {
    helpers: {
      metaTags,
      schemaOrg,
      schemaContent,
      schemaBreadcrumbs,
      renderTrackingScripts,
      isBrowser,
      ErrorBoundary,
      isDomReady,
      sanityContent,
      getUrlParameter: (name, location = (isBrowser && (window === null || window === void 0 ? void 0 : window.location)) || null) => {
        let _a
        return (_a = new URLSearchParams(location === null || location === void 0 ? void 0 : location.search)) === null || _a === void 0
          ? void 0
          : _a.get(name)
      },
      capitalise: string => (typeof string === "string" ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : ``),
      storage: {
        get: key => {
          // @ts-expect-error
          const stored = store.get(key)
          if (!stored) return null
          try {
            const item = JSON.parse(stored)
            const now = new Date()
            if (
              (item === null || item === void 0 ? void 0 : item.expiry) &&
              now.getTime() > (item === null || item === void 0 ? void 0 : item.expiry)
            ) {
              // @ts-expect-error
              store.remove(key)
              return null
            }
            return item === null || item === void 0 ? void 0 : item.value
          } catch (e) {
            return stored
          }
        },
        set: (key, value, days = 0) => {
          const now = new Date()
          const expiry = days && now.getTime() + days * 86400000
          const item = {
            value,
            expiry,
          }
          // @ts-expect-error
          store.set(key, JSON.stringify(item))
        },
        setAll: items =>
          items.map(item => {
            // @ts-expect-error
            return store.set(item === null || item === void 0 ? void 0 : item.key, item === null || item === void 0 ? void 0 : item.value)
          }),
        remove: key => {
          // @ts-expect-error
          return store.remove(key)
        },
        wipe: () => {
          // @ts-expect-error
          return store.clearAll()
        },
        all: () => {
          const output = []
          // @ts-expect-error
          store.each((value, key) => {
            output.push({ key, value })
          })
          return output
        },
      },
      schemaData: (
        {
          breadcrumbs,
          data,
          global,
          language,
          organisation = null,
          preferences = null,
          routes,
          social,
          template = null,
          templateMapping = null,
          tracking,
          url,
        },
        mapping
      ) => {
        let _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s
        const schemaTypes = (data, mapping) =>
          Object.keys(mapping).find(key => (data === null || data === void 0 ? void 0 : data.hasOwnProperty(key))) || ""
        const schemaDocument = (data, mapping) =>
          (data === null || data === void 0 ? void 0 : data.hasOwnProperty(schemaTypes(data, mapping)))
            ? data[schemaTypes(data, mapping)]
            : {}
        const mappedDocument = (0, schemaDocument)(data, mapping)
        const document = Object.assign(
          Object.assign({}, mappedDocument),
          ((_a = mappedDocument === null || mappedDocument === void 0 ? void 0 : mappedDocument.shopify) === null || _a === void 0
            ? void 0
            : _a.raw) &&
            Object.assign(
              {},
              JSON.parse(
                (_b = mappedDocument === null || mappedDocument === void 0 ? void 0 : mappedDocument.shopify) === null || _b === void 0
                  ? void 0
                  : _b.raw
              )
            )
        )
        const schemaTemplate = (template = null, item = null, templateMapping = null) => {
          var _a
          const mapping = templateMapping || {
            title: (item === null || item === void 0 ? void 0 : item.title) || "",
            description:
              ((_a = item === null || item === void 0 ? void 0 : item.description) === null || _a === void 0 ? void 0 : _a.split(".")[0]) ||
              "",
          }
          const regex = new RegExp(
            Object.keys(mapping)
              .map(key => `\{${key}\}`)
              .join("|"),
            "gi"
          )
          return template
            ? Object.entries(template).reduce(
                (obj, [key, value]) =>
                  Object.assign(Object.assign({}, obj), { [key]: value.replace(regex, item => mapping[item.replace(/[{}]/g, "")]) }),
                {}
              )
            : null
        }

        const templated = (0, schemaTemplate)(template, document, templateMapping)
        const schema = mapping[schemaTypes(data, mapping)] || false
        const type = schemaTypes(data, mapping) || "website"
        const sanityMeta =
          (document === null || document === void 0 ? void 0 : document.metadata) ||
          ((_e =
            (_d = (_c = data === null || data === void 0 ? void 0 : data.content) === null || _c === void 0 ? void 0 : _c.edges[0]) ===
              null || _d === void 0
              ? void 0
              : _d.node) === null || _e === void 0
            ? void 0
            : _e.metadata) ||
          {}
        const shopifyMeta =
          (document === null || document === void 0 ? void 0 : document.shopify) ||
          ((_h =
            (_g = (_f = data === null || data === void 0 ? void 0 : data.content) === null || _f === void 0 ? void 0 : _f.edges[0]) ===
              null || _g === void 0
              ? void 0
              : _g.node) === null || _h === void 0
            ? void 0
            : _h.shopify) ||
          {}
        const meta = Object.assign(Object.assign({}, sanityMeta), {
          title:
            (sanityMeta === null || sanityMeta === void 0 ? void 0 : sanityMeta.title) ||
            (shopifyMeta === null || shopifyMeta === void 0 ? void 0 : shopifyMeta.seoTitle),
          description:
            (sanityMeta === null || sanityMeta === void 0 ? void 0 : sanityMeta.description) ||
            (shopifyMeta === null || shopifyMeta === void 0 ? void 0 : shopifyMeta.seoDescription),
        })
        const org = organisation || preferences
        const separator = (org === null || org === void 0 ? void 0 : org.separator) || `|`
        const metaLang = language || "en_AU"
        const siteName = (org === null || org === void 0 ? void 0 : org.title) || ""
        const siteDescription = (org === null || org === void 0 ? void 0 : org.description) || ""
        const siteURL = (org === null || org === void 0 ? void 0 : org.url) || ""
        const pageTitle =
          (meta === null || meta === void 0 ? void 0 : meta.title) ||
          (templated === null || templated === void 0 ? void 0 : templated.title) ||
          (document === null || document === void 0 ? void 0 : document.title) ||
          (global === null || global === void 0 ? void 0 : global.title)
        const title = pageTitle !== siteName && pageTitle && siteName ? `${pageTitle} ${separator} ${siteName}` : siteName || ""
        const description =
          (meta === null || meta === void 0 ? void 0 : meta.description) ||
          (templated === null || templated === void 0 ? void 0 : templated.description) ||
          (document === null || document === void 0 ? void 0 : document.description) ||
          (global === null || global === void 0 ? void 0 : global.description) ||
          siteDescription ||
          ""
        const pageUrl = `${siteURL}${url}` || ""
        const author = (org === null || org === void 0 ? void 0 : org.author) || ""
        const robots = (meta === null || meta === void 0 ? void 0 : meta.noIndex) ? "noindex, nofollow" : "all"
        const canonical = (meta === null || meta === void 0 ? void 0 : meta.canonicalUrl)
          ? `${siteURL}${meta === null || meta === void 0 ? void 0 : meta.canonicalUrl}`
          : pageUrl
        const facebookAppID = (tracking === null || tracking === void 0 ? void 0 : tracking.facebookAppId) || ""
        const googleSiteVerifications = (
          (_j = tracking === null || tracking === void 0 ? void 0 : tracking.googleSiteVerifications) === null || _j === void 0
            ? void 0
            : _j.length
        )
          ? tracking.googleSiteVerifications
          : (tracking === null || tracking === void 0 ? void 0 : tracking.googleSiteVerify)
          ? [tracking.googleSiteVerify]
          : []
        const searchURL = `${siteURL}${(routes === null || routes === void 0 ? void 0 : routes.SEARCH) || "/"}`
        const socialProfiles =
          ((_k = social === null || social === void 0 ? void 0 : social.channels) === null || _k === void 0
            ? void 0
            : _k.map(channel => (channel === null || channel === void 0 ? void 0 : channel.url))) || []
        const twitterUser =
          ((_l = social === null || social === void 0 ? void 0 : social.channels) === null || _l === void 0
            ? void 0
            : _l
                .filter(channel => {
                  var _a
                  return (_a = channel === null || channel === void 0 ? void 0 : channel.title) === null || _a === void 0
                    ? void 0
                    : _a.toLowerCase().includes("twitter")
                })
                .map(channel => (channel === null || channel === void 0 ? void 0 : channel.url.replace("https://twitter.com/", "")))) || ""
        const schemaImage = image => ({
          "@type": "ImageObject",
          description: image.alt || "",
          url: image.url || "",
        })

        const siteImage = (0, schemaImage)({
          alt: `${siteName}`,
          url: `${
            (_o = (_m = org === null || org === void 0 ? void 0 : org.logo) === null || _m === void 0 ? void 0 : _m.asset) === null ||
            _o === void 0
              ? void 0
              : _o.url
          }`,
        })
        const schemaAddress = ({ addressLocality, addressRegion, postalCode, streetAddress }) => ({
          "@type": "PostalAddress",
          addressLocality: addressLocality || "",
          addressRegion: addressRegion || "",
          postalCode: postalCode || "",
          streetAddress: streetAddress || "",
        })

        const address = (0, schemaAddress)(Object.assign({}, organisation))
        const image = (0, schemaImage)({
          alt: `${title}`,
          url: `${
            ((_q = (_p = meta === null || meta === void 0 ? void 0 : meta.image) === null || _p === void 0 ? void 0 : _p.asset) === null ||
            _q === void 0
              ? void 0
              : _q.url) ||
            ((_s = (_r = org === null || org === void 0 ? void 0 : org.logo) === null || _r === void 0 ? void 0 : _r.asset) === null ||
            _s === void 0
              ? void 0
              : _s.url)
          }`,
        })
        const schemaListItem = ({ url, name, position }) => ({
          "@type": "ListItem",
          item: {
            "@id": url,
            name,
          },
          position,
        })

        const listItems = [
          (0, schemaListItem)({ name: "Homepage", url: siteURL, position: 1 }),
          ...((breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length)
            ? breadcrumbs.map(({ name, position, url }) =>
                listItems.push(
                  (0, schemaListItem)({
                    name,
                    url: `${siteURL}${url}`,
                    position: position || listItems.length + 1,
                  })
                )
              )
            : []),
        ]
        listItems.push((0, schemaListItem)({ name: title, url: pageUrl, position: listItems.length + 1 }))
        return {
          schema,
          document,
          type,
          metaLang,
          siteName,
          siteURL,
          title,
          description,
          pageUrl,
          author,
          robots,
          canonical,
          facebookAppID,
          googleSiteVerifications,
          socialProfiles,
          twitterUser,
          siteImage,
          image,
          address,
          searchURL,
          listItems,
        }
      },
      edgeNormaliser: item => {
        let _a
        return (
          ((_a = item === null || item === void 0 ? void 0 : item.edges) === null || _a === void 0 ? void 0 : _a.map(({ node }) => node)) ||
          item || [item] ||
          []
        )
      },
      handleize: string =>
        string
          .toLowerCase()
          .replace(/[^\w\u00C0-\u024f]+/g, "-")
          .replace(/^-+|-+$/g, ""),
      decodeBase64: string => {
        try {
          return atob(string)
        } catch (err) {
          return string
        }
      },
      encodeBase64: string => {
        try {
          return btoa(string)
        } catch (err) {
          return string
        }
      },
      setUrlParameter: (
        name = "",
        value = "",
        location = (isBrowser && (window === null || window === void 0 ? void 0 : window.location)) || null
      ) => {
        const url = new URLSearchParams(location === null || location === void 0 ? void 0 : location.search)
        name && value
          ? url === null || url === void 0
            ? void 0
            : url.set(name, value)
          : name && (url === null || url === void 0 ? void 0 : url.delete(name))
        return `${location === null || location === void 0 ? void 0 : location.pathname}${
          (url === null || url === void 0 ? void 0 : url.toString()) ? `?${url === null || url === void 0 ? void 0 : url.toString()}` : ""
        }`
      },
    },
  }
}
