import React from 'react'
import config from './config.default'
import Layout from './src/components/Layout'
import Providers from './src/providers/providers'
import { withLayout } from './src/hoc/Layout'

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>
}

const WrapElement = ({ children }: { children: any }) => <div>{children}</div>

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props
  // if no layout is specified in the page context
  if (pageContext.layout === 'NEW_ISSUE') {
    const WrapLayout = withLayout(WrapElement)
    return (
      <WrapLayout
        {...props}
        data={{
          monthlyIssue: {
            handle: { current: 'issue-oct-2023' },
          },
        }}
      >
        {element}
      </WrapLayout>
    )
  } else {
    return (
      <Layout {...props} settings={config.settings}>
        {element}
      </Layout>
    )
  }
}
