export const Radio = {
  baseStyle: {
    container: {
      cursor: "pointer",
      _hover: {
        textDecoration: "underline",
      },
    },
    control: {
      borderRadius: "none",
      bg: "transparent",
      border: "1px solid",
      borderColor: "black",
      h: 4.5,
      w: 4.5,
      _before: {
        content: `" "`,
        transition: "all 0.2s ease",
        transform: "scale(0)",
      },
      _checked: {
        bg: "transparent",
        borderColor: "black",
        _before: {
          borderRadius: "none",
          bg: "black",
          transform: "scale(1)",
        },
        _hover: {
          bg: "transparent",
          borderColor: "grey.text",
        },
      },
      _disabled: {
        bg: "grey.dark",
        borderColor: "grey.dark",
        _checked: {
          bg: "grey.dark",
          borderColor: "grey.dark",
          _before: {
            bg: "grey.inactive",
          },
        },
        _hover: {
          bg: "grey.dark",
          borderColor: "grey.dark",
        },
      },
      _hover: {
        bg: "transparent",
        borderColor: "grey.text",
        _before: {
          bg: "grey.text",
        },
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
    label: {
      fontSize: "sm",
    },
  },
  defaultProps: {
    size: "base",
  },
}
