import { useStaticQuery, graphql } from 'gatsby'
import { useRoutes } from './useRoutes'
import { useAppContext } from '../providers/app'
import { navigate } from 'gatsby'
import moment from 'moment'

export const useCurrentIssue = () => {
  const { urlResolver } = useRoutes()
  const { currentIssue } =
    useStaticQuery<GatsbyTypes.StaticCurrentIssueQuery>(graphql`
      query StaticCurrentIssue {
        currentIssue: sanitySettingCurrentIssue {
          issue {
            date
            id
            introText
            coverImage: _rawCoverImage(resolveReferences: { maxDepth: 2 })
            title
            rawFeateredArticle: _rawFeaturedArticle(
              resolveReferences: { maxDepth: 3 }
            )
            rawArticles: _rawArticles(resolveReferences: { maxDepth: 4 })
            headerTextBtn
            mobileMenuBtn
            issueButton
          }
        }
      }
    `)
  const { dispatch } = useAppContext()
  const date = currentIssue?.issue?.date
  const momentDate = moment(date, 'YYYY/MM/DD')
  const issueMonth = momentDate.format('MMMM')
  const issueShortMonth = momentDate.format('MMM')
  const issueMonthAndYear = momentDate.format('MMMM/YYYY').split('/').join(' ')
  const issueShortMonthAndYear = momentDate
    .format('MMM/YYYY')
    .split('/')
    .join(' ')

  const openCurrentIssue = () => {
    navigate('/issue-oct-2023')
    /*
    dispatch({
      type: 'currentIssueSlider',
      payload: true,
    })
*/
  }

  const closeCurrentIssue = () => {
    dispatch({
      type: 'currentIssueSlider',
      payload: false,
    })
  }

  const buildCurrentArticles = () => {
    return currentIssue?.issue?.rawArticles.map((article: any) => {
      return {
        author: article?.author?.name || '',
        title: article?.title || '',
        description: article?.description || '',
        link: urlResolver(article),
        id: article?._id,
        categories:
          article?.categories?.map((category: any) => ({
            handle: category?.handle?.current,
          })) || [],
      }
    })
  }

  const currentIssueFeaturedArticle = {
    author: currentIssue?.issue?.rawFeateredArticle?.author?.name || '',
    title: currentIssue?.issue?.rawFeateredArticle?.title || '',
    description: currentIssue?.issue?.rawFeateredArticle?.description || '',
    link: urlResolver(currentIssue?.issue?.rawFeateredArticle),
  }

  const coverImage = {
    alt: currentIssue?.issue?.coverImage?.alt || '',
    src: currentIssue?.issue?.coverImage?.asset?.url || '',
  }

  const introText = currentIssue?.issue?.introText || ''
  const megaMenuBtn = currentIssue?.issue.mobileMenuBtn || ''
  const headerFooterTextBtn = currentIssue?.issue.headerTextBtn || ''
  const buttonText = currentIssue?.issue?.issueButton || ''
  const currentIssueArticles = buildCurrentArticles()

  return {
    currentIssueFeaturedArticle,
    currentIssueArticles,
    introText,
    date,
    issueMonthAndYear,
    issueShortMonthAndYear,
    issueShortMonth,
    issueMonth,
    coverImage,
    megaMenuBtn,
    headerFooterTextBtn,
    buttonText,
    openCurrentIssue,
    closeCurrentIssue,
  }
}
