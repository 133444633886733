import { useCallback } from "react"
import { useCore } from "./useCore"
import { useTrackingContext } from "../providers/tracking"

export const useAnalytics = () => {
  const { tracked, gtm, latestVersion, VisibilitySensor } = useTrackingContext()
  const {
    helpers: { isDev, capitalise },
  } = useCore()

  const decorateUrl = (url: string): string => {
    if (typeof window !== "undefined") {
      // @ts-ignore next-line
      const ga = window[window["GoogleAnalyticsObject"]]
      // @ts-ignore next-line
      if (ga && typeof ga.getAll === "function") {
        // @ts-ignore next-line
        const tracker = ga.getAll()[0]
        // @ts-ignore next-line
        url = new window.gaplugins.Linker(tracker).decorate(url)
      }
      return url
    } else {
      return url
    }
  }

  const pushDataLayer = useCallback(
    (dataLayer: any) => {
      if (gtm) {
        isDev ? console.log({ ...dataLayer }) : gtm.dataLayer({ dataLayer })
      }
    },
    [gtm, isDev]
  )

  const trackPageView = useCallback(() => {
    if (tracked) {
      setTimeout(() => {
        const dataLayer = latestVersion
          ? {
              event: "page_view",
              page_title: document?.title,
              page_path: `${document?.location?.pathname}${document?.location?.search || ""}`,
              path_location: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
            }
          : {
              event: "Pageview",
              pagePath: `${document?.location?.pathname}${document?.location?.search ? document.location.search : ""}`,
              pageTitle: document?.title,
              originalLocation: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
            }
        pushDataLayer(dataLayer)
      }, 400)
    }
  }, [tracked, latestVersion, pushDataLayer])

  const trackPromoImpression = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "view_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionView",
              ecommerce: {
                promoView: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "select_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionClick",
              ecommerce: {
                promoClick: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackSignup = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "sign_up",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackShare = useCallback(
    async (method, type, id) => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "share",
            ecommerce: {
              method: capitalise(method),
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackClick = useCallback(
    async (type, id) => {
      if (type) {
        if (latestVersion) {
          const dataLayer = {
            event: "select_content",
            ecommerce: {
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackSearch = useCallback(
    async term => {
      if (term) {
        if (latestVersion) {
          const dataLayer = {
            event: "search",
            ecommerce: {
              search_term: term,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  return {
    tracked,
    trackPageView,
    trackPromoImpression,
    trackPromoClick,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    decorateUrl,
    VisibilitySensor,
  }
}
