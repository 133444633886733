import React from "react"
import {
  Box,
  Container,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  SimpleGrid,
  Image,
  Heading,
  Flex,
  Divider,
  Stack,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react"
import { motion } from "framer-motion"
import Icon from "../../components/Icon"
import { useAppContext } from "../../providers/app"
import { useCurrentIssue } from "../../hooks/useCurrentIssue"
import Link from "../../components/Link"

const CurrentIssueSlide = () => {
  const { state } = useAppContext()
  const { coverImage, issueMonthAndYear, introText, currentIssueArticles, closeCurrentIssue } = useCurrentIssue()

  return (
    <>
      <Drawer placement="left" isOpen={state.currentIssueSlider} onClose={closeCurrentIssue}>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
          <Box
            padding="20px"
            h={["60px", "64px"]}
            w={["60px", "64px"]}
            bg="brand.secondary"
            position="fixed"
            left={["80%", "80%", "80%", "80%", "1480px"]}
            top="0"
            cursor="pointer"
            onClick={closeCurrentIssue}
            zIndex={2000}
          >
            <Icon name="cross" width="100%" height="100%" title="Close" stroke="white" />
          </Box>
        </motion.div>

        <DrawerContent maxW={["80%", "80%", "80%", "80%", "1480px"]} overflow="scroll" bg="brand.primary">
          <Box p="40px" color="white" position="relative">
            <Container maxW="container.xl" mt="28">
              <SimpleGrid columns={[1, 1, 2]} spacing="8" color="white">
                <Image mt="2" {...coverImage} width="100%" />
                <Flex alignItems="center" direction="column" mt="12">
                  <Heading as="h1" size="h1" color="inherit">
                    {issueMonthAndYear}
                  </Heading>
                  <Divider borderColor="brand.secondary" my="12" />
                  <Heading as="h4" size="h4" color="inherit" mb="12">
                    {introText}
                  </Heading>
                  <Stack spacing="12">
                    {currentIssueArticles?.map(article => {
                      return (
                        <Flex key={article?.id} alignItems="center" textAlign="center" direction="column">
                          <LinkBox as="article">
                            <LinkOverlay as={Link} to={article?.link.url} title={article?.link.title}>
                              <Heading as="h2" size="h2" color="inherit" mb="4">
                                {article?.title}
                              </Heading>
                              <Heading as="h5" size="h5" color="inherit">
                                {article?.author}
                              </Heading>
                            </LinkOverlay>
                          </LinkBox>
                        </Flex>
                      )
                    })}
                  </Stack>
                </Flex>
              </SimpleGrid>
            </Container>
          </Box>
        </DrawerContent>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.7 }}>
          <DrawerOverlay />
        </motion.div>
      </Drawer>
    </>
  )
}

export default React.memo(CurrentIssueSlide)
