import { fonts } from "src/theme/fonts"

export const Text = {
  baseStyle: {
    color: "brand.primary",
    fontFamily: fonts.body,
    fontWeight: "400",
    fontSize: "16px",
    transition: "all .4s ease",
  },
  sizes: {
    regular: {
      lineHeight: "23.08px",
    },
    large: {
      fontSize: "18px",
      lineHeight: "23.27px",
    },
    article: {
      fontFamily: "Georgia",
      fontSize: "18px",
      lineHeight: "20.45px",
    },
  },
  variants: {
    hoverText: {
      transitionProperty: "common",
      transitionDuration: "normal",
      _hover: {
        color: "brand.secondary",
      },
    },
  },
}
