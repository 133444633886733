export default {
  app: {
    title: 'InStyle',
    description: 'Fashion, Beauty, Culture and Lifestyle',
    url: 'https://instyleaustralia.com.au',
    logo: 'static/images/logo.svg',
    themeColor: '#000000',
    themeBackground: '#FFFFFF',
    themeDisplay: 'minimal-ui',
    themeIcon: 'static/images/icon.png',
  },
  queries: {
    newIssue: {
      template: 'templates/newIssue.tsx',
    },
    monthlyIssues: {
      query: `allSanityMonthlyIssue { edges { node { _id handle { current } articles { _id handle { current } } } } }`,
      template: 'templates/monthlyIssue.tsx',
      articleTemplate: 'templates/monthlyIssueArticle.tsx',
      devPageLimit: 100,
    },
    shop: {
      query: `allSanityShop(sort: { orderRank: ASC}) { edges { node { _id handle { current } } } }`,
      template: 'templates/shop.tsx',
      devPageLimit: 100,
    },
    categories: {
      query: `allSanityCategory { edges { node { _id handle { current } } } }`,
      template: 'templates/category.tsx',
      all: 'templates/categoryList.tsx',
      devPageLimit: 100,
    },
    articles: {
      query: `allSanityArticle { edges { node { _id handle { current } categories { handle { current } } } } }`,
      template: 'templates/article.tsx',
      devPageLimit: 100,
    },
    flexible: {
      query: `allSanityPageFlexible { edges { node { _id handle { current } } } }`,
      template: 'templates/flexible.tsx',
    },
    generic: {
      query: `allSanityPageGeneric { edges { node { _id handle { current } } } }`,
      template: 'templates/generic.tsx',
    },
    redirects: {
      query: `allSanityRedirect { edges { node { source type destination } } }`,
      devPageLimit: 0,
    },
    robots: {
      query: `allSanitySettingRobots { nodes { content { code } } }`,
    },
  },
  services: {
    sanity: {
      projectId: 'cbyxytey',
      token:
        'sk3gw0HaOCtbHIx2qLe60j623Q8jbCn0BM6RFcTBItY1511LNQipCPo2SxILuSV3GwWvoiXMGEU7mHC7LAx7VGl6U5oWzpKcKgBhcyNuuiTzDZBhor9nlglV62CXuocx4Kun0RKGDu8BJE7tMPXCX5L2aLiaGjIRaqwfxrgmARQVSZR6JZoQ',
      dataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
    },
  },
  settings: {
    keys: {
      announcement: 'instyle:announcement',
      maintenance: 'instyle:maintenance',
      password: 'instyle:password',
    },
    params: {
      preview: 'preview',
    },
    routes: {
      HOMEPAGE: '/',
      FLEXIBLE: '',
      GENERIC: '',
      PAGE: '',
      ALLCATEGORIES: '/all',
      ARTICLE: '',
      MONTHLY_ISSUES: '',
      CATEGORY: '',
      SHOP: '/shop',
      PRODUCT: '',
      ERROR: '/404',
      SUBSCRIBE: '/subscribe',
      SEARCH: '/search',
      PASSWORD: '/password',
    },
    tracking: {
      ga: 'UA-224024531-1',
      hotjarId: 2896203,
      hotjarSv: 6,
    },
    defaultRedirects: [],
    clientPaths: [],
    sitemapExclusions: [
      '/offline-plugin-app-shell-fallback',
      '/dev-404-page',
      '/404',
      '/404.html',
      '/password',
      '/preview',
      '/search',
    ],
  },
}
