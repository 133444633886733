import React from "react"
import Link from "../../../components/Link"
import { HStack, Text, Flex } from "@chakra-ui/react"
import { useNavigation } from "../../../hooks/useNavigation"
import { useMasthead } from "../../../hooks/useMasthead"

type Props = {
  setMegaActive: any
}

const NavigationHeaderDesktop: React.FC<Props> = ({ setMegaActive }) => {
  const { header: items } = useNavigation()
  const { color } = useMasthead()

  return items?.length > 0 ? (
    <>
      <HStack as="nav" className="desktop-navigation-container" spacing={4} display={["none", "block"]} mr="4">
        <Flex direction="row">
          {items.map(({ title, url, order }: { title: string; url: string; order: number }, index: number) => {
            return (
              <Text
                className="nav-item"
                key={`header-${index}`}
                order={order}
                as={Link}
                to={url}
                color={color}
                fontSize="sm"
                letterSpacing={["1.12px", "1.6px"]}
                textTransform="uppercase"
                mr="5"
              >
                {title}
              </Text>
            )
          })}
          <Text
            as="button"
            variant="hoverText"
            display="inline"
            fontSize="sm"
            color={color}
            textTransform="uppercase"
            onMouseEnter={() => setMegaActive(true)}
            order={5}
          >
            More... {/* TODO: add to sanity */}
          </Text>
        </Flex>
      </HStack>
    </>
  ) : null
}

export default React.memo(NavigationHeaderDesktop)
