import React, { useRef } from "react"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Heading, ModalCloseButton } from "@chakra-ui/react"
import { useAppContext } from "../../providers/app"
import Icon from "../../components/Icon"
import SubscribeForm from "./SubscribeForm"

const SubscribePopup: React.FC = () => {
  const { state, dispatch } = useAppContext()
  const initialRef = useRef()

  const onClose = () => {
    dispatch({
      type: "subscribe",
      payload: !state.activeSubscribe,
    })
  }

  return (
    <Modal onClose={onClose} isOpen={state.activeSubscribe} initialFocusRef={initialRef} motionPreset="slideInBottom" isCentered>
      <ModalOverlay bg="blackAlpha.500" />
      <ModalContent p={["4", "8"]} maxW={["92%", "2xl", "3xl"]}>
        <ModalHeader pb={["4", "6"]}>
          <Heading as="h2" size="h2" textAlign="center" pt={["4", "0"]}>
            Stay up-to-date on your favorite celebs, fashion, beauty and more.{/* TODO: add to sanity */}
          </Heading>
        </ModalHeader>
        <ModalCloseButton
          bg="brand.secondary"
          borderRadius="inital"
          p="4"
          height="50px"
          width="50px"
          mt="-8px"
          mr={["-12px", "-62px"]}
          _hover={{ bg: "brand.primary" }}
        >
          <Icon name="cross" title="cross" height={25} width={25} stroke="white" />
        </ModalCloseButton>
        <ModalBody>
          <SubscribeForm initialRef={initialRef} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(SubscribePopup)
