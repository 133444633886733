import { useStaticQuery, graphql } from 'gatsby'
import { navigate } from 'gatsby'
import {
  IGatsbySanityHandle,
  IGatsbySanityImage,
} from '../types/GatsbySanity.types'

type StaticCurrentMonthlyIssueQuery = {
  currentMonthlyIssue: {
    handle: IGatsbySanityHandle
    coverImage: IGatsbySanityImage
  }
}

export const useCurrentMonthlyIssue = () => {
  const { currentIssue } = useStaticQuery<{
    currentIssue: StaticCurrentMonthlyIssueQuery
  }>(graphql`
    query StaticCurrentMonthlyIssue {
      currentIssue: sanitySettingCurrentMonthlyIssue {
        currentMonthlyIssue {
          _id
          handle {
            current
          }
          coverImage {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  const handle = currentIssue?.currentMonthlyIssue?.handle?.current
  const coverImageUrl =
    currentIssue?.currentMonthlyIssue?.coverImage?.asset?.url
  const openCurrentMonthlyIssue = () => {
    navigate(`/${handle}`)
  }
  return {
    openCurrentMonthlyIssue,
    coverImageUrl,
    handle,
  }
}
