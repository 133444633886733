type CallbackFunction = () => void

export const throttle = (func: CallbackFunction, delay: number) => {
  let inProgress = false
  return (...args) => {
    if (inProgress) {
      return
    }
    inProgress = true
    setTimeout(() => {
      func(...args)
      inProgress = false
    }, delay)
  }
}
