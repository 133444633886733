import { useCallback, useMemo, useState } from 'react'
import { useWindowSize } from './useWindowSize'
import {
  GOOGLE_ADS_MONTHLY_ISSUE,
  NEW_ISSUE,
} from '../constants/NewIssue.constatns'

export const useDFPAds = () => {
  const site = 'InStyleAustralia'
  const networkId = '22685820863'
  const getKvAdTest: (location: any) => string = (location) =>
    location?.search?.includes('adtest')
      ? location?.search?.split('adtest=').pop()
      : 'false'
  const env = useMemo(
    () =>
      process.env.SANITY_PROJECT_DATASET === 'staging'
        ? 'staging'
        : 'production',
    [process.env.SANITY_PROJECT_DATASET],
  )
  const sponsored = 'N' // TODO: Sanity schema checkbox for sponsored articles
  const windowSize = useWindowSize()
  const skin = windowSize?.width && windowSize.width >= 1440 ? 'Y' : 'N'
  const [targeting, setTargeting] = useState<any>(null)
  const [adUnit, setAdUnit] = useState<string | null>(null)

  const [mobileAnchor, setMobileAnchor] = useState<any>(null)
  const [interstitual, setInterstitual] = useState<any>(null)

  // Oop sizes are called outside of react-gpt and need to be a different format
  // prettier-ignore
  const oopSizeMap = useMemo(() => ({
    anchor: [[[767,0],[]],[[0,0],[[320,50]]]],
    interstitial: [[[767,0],[]],[[0,0],[[300,250],[320,480],[336,280]]]],
  }), [])
  // prettier-ignore
  const sizeMap = useMemo(() => ({
    header: [
      {
        viewport: [1023, 0], // first value is screen min-width breakpoint
        sizes: [
          [970, 250], // following are avaiable creative sizes // TODO: add back in when there is a creative
        ],
      },
      {
        viewport: [767, 0], // breakpoint
        sizes: [[728, 90]], // creative
      },
      {
        viewport: [0, 0],
        sizes: [[300, 250]],
      }
    ],
    billboard: [
      {
        viewport: [1023, 0],
        sizes: [[970, 250]],
      },
      {
        viewport: [767, 0],
        sizes: [[728, 90]],
      },
      {
        viewport: [0, 0],
        sizes: [[300, 250]],
      }
    ],
    sidebar: [
      {
        viewport: [1023, 0],
        sizes: [[300, 600], [300, 250],
        ],
      },
      {
        viewport: [0, 0],
        sizes: [],
      }
    ],
    featarticle: [
      {
        viewport: [1023, 0],
        sizes: [[970, 250], [728, 90], [576, 324], "fluid"]
      },
      {
        viewport: [767, 0],
        sizes: [[300, 250], [576, 324], "fluid"],
      },
      {
        viewport: [0, 0],
        sizes: [[300, 250], [320, 180], "fluid"],
      }
    ],
    'monthly-issue': GOOGLE_ADS_MONTHLY_ISSUE,
    midarticle: [
      {
        viewport: [767, 0],
        sizes: [[300, 250], [576, 324], "fluid"],
      },
      {
        viewport: [0, 0],
        sizes: [[300, 250], [320, 180], "fluid"],
      }
    ],
    outstream: [
      {
        viewport: [0, 0],
        sizes: [[1, 1]],
      }
    ],
  }), [])

  const defaultSizes = {
    header: ['970', '250'],
    billboard: ['970', '250'],
    anchor: null,
    'monthly-issue': NEW_ISSUE.GOOGLE_ADS_SIZES.DESKTOP,
    interstitial: ['767', '0'],
    sidebar: ['300', '600'],
    featarticle: ['1023', '0'],
    midarticle: ['767', '0'],
    outstream: ['1', '1'],
  }

  const lazyLoadParams = {
    fetchMarginPercent: 200,
    renderMarginPercent: 50,
    mobileScaling: 2.0,
  }

  const buildOopAd = useCallback(
    ({ type, id, googletag }) => {
      // console.log("build oop ad", adUnit, targeting)
      if (!adUnit || !targeting) return
      let ad = null
      if (type === 'anchor') {
        if (mobileAnchor) googletag.destroySlots([mobileAnchor])
        ad = googletag.defineOutOfPageSlot(
          `${networkId}/${adUnit}`,
          googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR,
        )
      }
      if (type === 'interstitial') {
        if (interstitual) googletag.destroySlots([interstitual])
        ad = googletag.defineOutOfPageSlot(
          `${networkId}/${site}/interstitial`,
          googletag.enums.OutOfPageFormat.INTERSTITIAL,
        )
      }
      if (document && !document.getElementById(id) && type === 'outOfPage') {
        ad = googletag.defineOutOfPageSlot(`${networkId}/${adUnit}`, id)
      }

      if (ad) {
        ad.setTargeting('pos', '0').addService(googletag.pubads())

        console.log('ad-debug targeting 2', targeting)
        Object.keys(targeting).forEach((key) => {
          console.log('ad-debug setTargeting', key, targeting[key])
          ad.setTargeting(key, targeting[key])
        })
        if (document && type === 'outOfPage' && id) {
          const oopDiv = document.createElement('div')
          oopDiv.id = id
          document.getElementsByTagName('body')[0].appendChild(oopDiv)
          googletag.display(id)
        } else {
          console.log('ad-debug ad defineSizeMapping', type, oopSizeMap[type])
          ad.defineSizeMapping(oopSizeMap[type])
          googletag.display(ad)
        }

        if (type === 'anchor') setMobileAnchor(ad)
        if (type === 'interstitual') setInterstitual(ad)
      }
    },
    [adUnit, oopSizeMap, targeting, mobileAnchor],
  )

  const createOopAds = (googletag, adUnit) => {
    googletag.cmd.push(() => {
      buildOopAd({ adUnit, type: 'anchor', googletag })
      buildOopAd({ adUnit, type: 'interstitial', googletag })
      buildOopAd({ adUnit, type: 'outOfPage', id: 'gam_oop_pos0', googletag })
    })
  }

  return {
    networkId,
    sizeMap,
    defaultSizes,
    site,
    createOopAds,
    targeting,
    env,
    sponsored,
    skin,
    getKvAdTest,
    setTargeting,
    lazyLoadParams,
    adUnit,
    setAdUnit,
  }
}
