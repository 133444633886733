import React from "react"
import Link from "../../../components/Link"
import {
  Flex,
  VStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  Heading,
  Image,
  Button,
  HStack,
} from "@chakra-ui/react"
import { useNavigation } from "../../../hooks/useNavigation"
import { useAppContext } from "../../../providers/app"
import { useCurrentIssue } from "../../../hooks/useCurrentIssue"
import Brand from "../../../components/Brand"
import SocialMedia from "../../../components/SocialMedia"
import CurrentIssueDate from "../../../components/CurrentIssue/CurrentIssueDate"

const NavigationHeaderMobile: React.FC = () => {
  const { megaMenu: sections } = useNavigation()
  const { state, dispatch } = useAppContext()

  const { megaMenuBtn, openCurrentIssue } = useCurrentIssue()

  const onToggle = () => {
    dispatch({
      type: "mobileMenu",
      payload: !state.mobileMenu,
    })
  }

  console.log("sections", sections)
  const subscribeLink = sections && sections[1]?.items.filter((item: any) => item.title.toLowerCase().includes("subscribe"))

  return (
    <Drawer isOpen={state.mobileMenu} placement="left" onClose={onToggle} motionPreset="slideInLeft" size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader display="flex" alignItems="center">
          <HStack spacing="4">
            <Brand width={122} height={30} />
            <CurrentIssueDate color="black" textAlign="left" fontSize="12px" />
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          <Flex direction="column" mt="8">
            <Flex direction="column">
              <VStack spacing="4" alignItems="left">
                <Heading as="h1" size="h1" onClick={openCurrentIssue} cursor="pointer" _hover={{ textDecoration: "underline" }}>
                  {megaMenuBtn}
                </Heading>

                {sections &&
                  sections[0]?.items.map(({ title, url, order }: { title: string; url: string; order: number }, index: number) => {
                    return (
                      <Heading
                        size="h1"
                        as={Link}
                        key={`col-1-${index}`}
                        to={url}
                        textDecoration="auto !important"
                        _hover={{ color: "brand.secondary" }}
                        order={order}
                        mt="4"
                      >
                        {title}
                      </Heading>
                    )
                  })}
              </VStack>
            </Flex>
            <Flex direction="column" mt="8">
              <VStack spacing="4" alignItems="left">
                {sections &&
                  sections[1]?.items.map(({ title, url, order }: { title: string; url: string; order: number }, index: number) => {
                    const isSubscribe = title.toLowerCase() === "subscribe"
                    return (
                      !isSubscribe && (
                        <React.Fragment key={`sub-${index}`}>
                          <Heading as={Link} size="h4" to={url} order={order}>
                            {title}
                          </Heading>
                        </React.Fragment>
                      )
                    )
                  })}
              </VStack>
            </Flex>

            <Flex direction="column" bgColor={(sections && sections[2]?.bgColor?.hex) || "brand.secondary"} p="8" mt="8">
              {sections && sections[2]?.image.src ? (
                <Image objectFit="cover" src={sections[2].image?.src} alt={sections[2].image?.alt} width="100%" />
              ) : (
                <>
                  {sections[2]?.content && (
                    <Flex justifyContent="center" textAlign="center" color="white">
                      {sections[2].content}
                    </Flex>
                  )}
                  {sections[2]?.btnLink && (
                    <Link
                      title={sections[2].btnLink?.title}
                      to={sections[2].btnLink?.link}
                      textDecoration="none !important"
                      _hover={{ textDecoration: "none" }}
                      mt="8"
                    >
                      <Button variant="outlinedReversed">{sections[2].btnLink?.title}</Button>
                    </Link>
                  )}
                </>
              )}
            </Flex>

            <Flex direction="column" mt="8">
              {subscribeLink && (
                <Heading as={Link} size="h4" to={subscribeLink[0]?.url} pb={6}>
                  {subscribeLink[0]?.title}
                </Heading>
              )}
              <Heading
                size="h4"
                _hover={{ color: "brand.secondary" }}
                cursor="pointer"
                onClick={() => {
                  dispatch({
                    type: "subscribe",
                    payload: true,
                  })
                }}
              >
                Sign up
              </Heading>
              <Divider mt="8" />
            </Flex>
          </Flex>
          <SocialMedia color="black" fontSize="12px" fill="none" stroke="black" mt="8" alignment="left" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default React.memo(NavigationHeaderMobile)
