import React from "react"
import { Box, Container, Drawer, DrawerOverlay, DrawerContent, Heading } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { navigate } from "gatsby"
import SearchInput from "../../components/Search/SearchInput"
import Icon from "../../components/Icon"
import { useAppContext } from "../../providers/app"

const SearchSlide = () => {
  const { state, dispatch } = useAppContext()

  const handleClose = () => {
    dispatch({
      type: "search",
      payload: false,
    })
  }

  const handleSubmit = (e: any) => {
    if (e.key === "Enter") {
      navigate("/search", {
        state: { search: e.target.value },
      })
    }
  }
  return (
    <>
      <Drawer placement="top" isOpen={state.activeSearch} onClose={handleClose}>
        <DrawerContent maxW="100%" bg="brand.primary" className="search-drawer">
          <Box position="absolute" top="10px" right="10px" height="23px" width="23px" cursor="pointer" zIndex={1} onClick={handleClose}>
            <Icon name="cross" width="100%" height="100%" title="Close" stroke="white" />
          </Box>

          <Box px="40px" pt="40px" pb="150px" color="white" position="relative">
            <Container maxW="container.xl" mt="28">
              <SearchInput
                onSubmit={(e: any) => {
                  handleSubmit(e)
                }}
                placeholder="Search"
                color="white"
                showClear={false}
              />
              <Heading as="h5" size="h5" color="white" mt="4">
                Type keyword and press enter {/* TODO: add to sanity */}
              </Heading>
            </Container>
          </Box>
        </DrawerContent>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.7 }}>
          <DrawerOverlay />
        </motion.div>
      </Drawer>
    </>
  )
}

export default React.memo(SearchSlide)
