import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import DiamondOne from './assets/diamond-1.png'
import DiamondTwo from './assets/diamond-2.png'
import DiamondThree from './assets/diamond-3.png'
import DiamondFour from './assets/diamond-4.png'
import DiamondFive from './assets/diamond-5.png'
import DiamondSix from './assets/diamond-6.png'
import { useMobile } from '../Hooks/useMoblie'
import { Box } from '@chakra-ui/react'

const StyledPandoraEffectContainer = styled.div<{
  $isMobile: boolean
}>`
  height: 200vh;
  overflow: hidden;
  position: absolute;
  top: -50px;
  left: 0;
  transition: opacity 500ms;
  width: 100%;
  pointer-events: none;

  @keyframes fall {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      top: 150vh;
      opacity: 0;
    }
  }

  @keyframes sway {
    0% {
      margin-left: 0;
    }
    25% {
      margin-left: 50px;
    }
    50% {
      margin-left: -50px;
    }
    75% {
      margin-left: 50px;
    }
    100% {
      margin-left: 0;
    }
  }

  .diamond {
    background-size: contain;
    background-repeat: no-repeat;
    animation: fall ease-in infinite, sway ease-in-out infinite;
    color: skyblue;
    position: absolute;
    ${({ $isMobile }: { $isMobile: boolean }) =>
      $isMobile
        ? `width: 25px; height: 25px;`
        : `min-width: 40px; min-height: 40px`}
  }
`

export const PandoraDiamondAnimation = () => {
  const { isMobile } = useMobile()
  const containerRef = useRef(null)

  useEffect(() => {
    const diamondContainer = document.getElementById('diamond-container')!
    const diamondContent = [
      DiamondOne,
      DiamondTwo,
      DiamondThree,
      DiamondFour,
      DiamondFive,
      DiamondSix,
    ]

    const random = (num: number) => {
      return Math.floor(Math.random() * num)
    }

    const getRandomStyles = () => {
      const top = random(100)
      const left = random(100)
      const dur = random(10) + 10
      const size = random(25) + 25
      return `
          top: -${top}%;
          left: ${left}%;
          font-size: ${size}px;
          animation-duration: ${dur}s;
        `
    }

    const creatediamond = (num: number) => {
      for (let i = num; i > 0; i--) {
        let randomDiamondBackground = diamondContent[random(6)]
        let diamond = document.createElement('div')
        diamond.className = 'diamond'
        diamond.style.cssText = getRandomStyles()
        diamond.style.backgroundImage = `url(${randomDiamondBackground})`
        diamondContainer.append(diamond)
      }
    }

    creatediamond(150)

    const dynamicallyFadeOut = (element: HTMLElement) => {
      let op = 1
      let timer = setInterval(function () {
        if (op <= 0.05) {
          clearInterval(timer)
          element.style.display = 'none'
        }
        element.style.opacity = op.toString()
        element.style.filter = 'alpha(opacity=' + op * 100 + ')'
        op -= op * 0.05
      }, 250)
    }

    // fade out animation begins after 15 sec
    setTimeout(() => {
      dynamicallyFadeOut(diamondContainer)
    }, 15000)
  }, [])

  return (
    <Box ref={containerRef} style={{ height: '100%' }}>
      <StyledPandoraEffectContainer
        id="diamond-container"
        $isMobile={isMobile}
      />
    </Box>
  )
}
