import React from 'react'
import Meta from '../components/Meta'
import Main from '../components/Main'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { withLayout } from '../hoc/Layout'
import Announcement from '../components/Announcement'
import SubscribePopup from '../components/Subscribe/SubscribePopup'
import CurrentIssueSlide from '../components/CurrentIssue/CurrentIssueSlide'
import SearchSlide from '../components/Search/SearchSlide'
import Global from '../theme/foundations/global'
import type { PageProps } from '../../types/global'
import { Box } from '@chakra-ui/react'
import { PandoraDiamondAnimation } from './NewIssue/PandoraDiamond/PandoraDiamond'

const Layout: React.FC<PageProps<any>> = ({ children, data, location }) => (
  <Box w="container.xl" maxWidth="100%" mx="auto">
    <Global />
    <Meta data={data} location={location} />
    <Announcement />
    <Header data={data} location={location} />
    <SearchSlide />
    <CurrentIssueSlide />
    <Main>{children}</Main>
    <Footer location={location} />
    <SubscribePopup />
    {process.env.FEATURE_FLAG_SPARKLE_DIAMONDS === 'true' && (
      <PandoraDiamondAnimation />
    )}
  </Box>
)

export default withLayout(Layout)
